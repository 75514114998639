import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";

import InputFileBase from "@houm-com/ui/InputFileBase";
import Button from "@houm-com/ui/Button";

import Header from "apps/lead/components/EvaluatorForm/HeaderForm/Header";
import FormWrapper from "apps/lead/components/EvaluatorForm/FormWrapper";
import {
  documentsToUpload,
  filterKey,
  getDocumentsArray,
} from "apps/lead/pages/EvaluatorForm/components/UploadDocuments/utils/utils";
import { useEvaluation } from "apps/lead/context/evaluatorContext/EvaluatorContext";
import useUpdateDocuments from "apps/lead/pages/EvaluatorForm/hooks/useUpdateDocuments";
import { ALLOWED_MIME_TYPES } from "apps/lead/pages/EvaluatorForm/utils/constants";
import { DocumentFileTypeEnum } from "apps/lead/context/evaluatorContext/EvaluatorTypes";

const UploadDocuments = () => {
  const history = useHistory();
  const {
    state: {
      user: { employment, memberId },
      country,
    },
  } = useEvaluation();

  const { isLoading, mutate, isSuccess } = useUpdateDocuments({
    memberId,
    country,
  });
  const [filesToLoad, setFilesToLoad] = useState<{ [x: string]: FileList }>({});

  const documentsToUploadFiltered = Object.values(documentsToUpload[country])
    .filter((value) => value.workerType.includes(employment.workerType))
    .map((value) => value.label);

  const onSubmit = () => {
    const files = getDocumentsArray(filesToLoad);
    mutate({
      [DocumentFileTypeEnum.OTHER]: files,
    });
  };

  useEffect(() => {
    if (isSuccess) history.go(0);
  }, [isSuccess]);

  return (
    <>
      <Header title="Documentación" />
      <FormWrapper classNameFormWrapper="flex flex-col gap-10">
        <div className="flex flex-col gap-2">
          <p className="text-p16 font-['Inter'] font-bold">
            ¿Que documentación debo subir?
          </p>
          <div>
            <ol className="list-decimal list-inside [&_li]:text-p16 [&_li]:font-['Inter']">
              {documentsToUploadFiltered.map((label, i) => (
                <li key={i}>{label}</li>
              ))}
            </ol>
          </div>
        </div>
        <div className="flex flex-col-reverse gap-3">
          {[...Array(Object.keys(filesToLoad).length + 1).keys()].map((id) => (
            <InputFileBase
              id={`${DocumentFileTypeEnum.OTHER}${id + 1}`}
              key={`${DocumentFileTypeEnum.OTHER}${id + 1}`}
              allowedMimeTypes={ALLOWED_MIME_TYPES}
              classNameInputButton="!w-full"
              splitLength={40}
              form="novalidateform"
              onLoadFile={(e) => {
                if (e.length === 0) {
                  const filesToLoadFiltered = filterKey(
                    filesToLoad,
                    `${DocumentFileTypeEnum.OTHER}${id + 1}`
                  );
                  setFilesToLoad(filesToLoadFiltered);
                } else {
                  setFilesToLoad({
                    ...filesToLoad,
                    [`${DocumentFileTypeEnum.OTHER}${id + 1}`]: e,
                  });
                }
              }}
            />
          ))}
        </div>
        <div className="flex justify-center">
          <Button
            size="lg"
            disabled={Object.keys(filesToLoad).length === 0}
            variant="primary"
            onClick={onSubmit}
            isLoading={isLoading}
          >
            Subir documentos
          </Button>
        </div>
      </FormWrapper>
    </>
  );
};

export default UploadDocuments;
