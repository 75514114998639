import { MemberRole } from "apps/lead/models/Evaluation";
import useCreateMember from "apps/lead/pages/EvaluatorForm/hooks/useCreateMember";
import useUpdateMember from "apps/lead/pages/EvaluatorForm/hooks/useUpdateMember";
import { MemberData } from "apps/lead/pages/EvaluatorForm/utils/types";
import { Locale } from "models/Countries";

interface Props {
  evaluationId: string;
  country: Locale;
  memberRole: MemberRole;
  memberId: string | undefined;
}

const useApplicantFormSubmit = ({
  evaluationId,
  country,
  memberRole,
  memberId,
}: Props) => {
  const {
    mutate: mutateCreate,
    isLoading: isLoadingCreate,
    isSuccess: isSuccessCreate,
    data: dataCreate,
  } = useCreateMember({
    evaluationId,
    country,
    memberRole,
  });
  const {
    mutate: mutateUpdate,
    isLoading: isLoadingUpdate,
    isSuccess: isSuccessUpdate,
    data: dataUpdate,
  } = useUpdateMember({
    memberId: memberId as string,
    country,
  });

  const onSubmit = (data: MemberData) => {
    if (memberId) mutateUpdate(data);
    else mutateCreate(data);
  };

  return {
    onSubmitFormData: onSubmit,
    isLoading: isLoadingCreate || isLoadingUpdate,
    isSuccess: isSuccessCreate || isSuccessUpdate,
    createResponse: dataCreate?.data,
    updateResponse: dataUpdate?.data,
  };
};

export default useApplicantFormSubmit;
