import { DocumentFiles } from "apps/lead/pages/EvaluatorForm/components/EmploymentValidationForm/utils/types";
import { IDependentFilesForm } from "apps/lead/pages/EvaluatorForm/components/EmploymentValidationForm/components/co/DependentForm/utils/types";
import {
  DocumentFileTypeEnum,
  EmploymentFiles,
} from "apps/lead/context/evaluatorContext/EvaluatorTypes";

export const parserMutationFiles = (
  data: DocumentFiles<IDependentFilesForm>
) => ({
  ...(data[DocumentFileTypeEnum.EMPLOYMENT_CERTIFICATE] && {
    [DocumentFileTypeEnum.EMPLOYMENT_CERTIFICATE]: [
      data[DocumentFileTypeEnum.EMPLOYMENT_CERTIFICATE],
    ],
  }),
});

export const parserDefaultData = (files: EmploymentFiles) => ({
  [DocumentFileTypeEnum.EMPLOYMENT_CERTIFICATE]:
    files?.[DocumentFileTypeEnum.EMPLOYMENT_CERTIFICATE]?.[0],
});
