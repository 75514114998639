import { getCountryPhone } from "utils/verifyPhoneNumber";
import { getFormattedPhone } from "apps/lead/utils/phone";
import { Country, PhoneInput } from "models/PhoneInput";
import { formatPhoneInput } from "utils";
import { Locale } from "models/Countries";

export const getDefaultPhone = (phone: string | null, country: Locale) => {
  const defaultCountry: Country = getCountryPhone(country);
  const defaultPhone: PhoneInput = { country: defaultCountry, phone: "" };
  if (typeof phone === "string") return formatPhoneInput(phone);
  return getFormattedPhone(defaultPhone, phone);
};

interface FileData {
  name: string;
  size: number;
  type: string;
}

export const getFileData = (file: FileList): FileData | undefined => {
  if (file.length === 0) return undefined;
  return {
    name: file[0].name,
    size: file[0].size,
    type: file[0].type,
  };
};
