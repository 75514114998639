/**
 *  Add your app private routes provider here.
 */
import { Route } from "react-router-dom";
import AccountPrivateRoutes from "apps/account/routes/AccountPrivateRoutes";
import LandlordPrivateRoutes from "apps/landlord/routes/LandlordPrivateRoutes";
import ErrorBoundary from "apps/lead/components/ErrorBoundary";
import TenantPrivateRoutes from "apps/tenant/routes/TenantPrivateRoutes";
import HelpWidget from "Components/Admin/UIComponents/HelpWidget";
import { useUserRole } from "../context/userRoleContext";
import UserCountryCorrectionDialog from "../components/UserCountryCorrectionDialog";
import Fallback from "../components/Fallback";

const MainPrivateRoutes = () => {
  const { isLandlord, isTenant } = useUserRole();
  return (
    <>
      <Route exact path="/">
        {isTenant ? <TenantPrivateRoutes /> : <LandlordPrivateRoutes />}
      </Route>
      <Route path="/account">
        <AccountPrivateRoutes />
      </Route>
      {isTenant && (
        <Route path="/tenant">
          <ErrorBoundary>
            <TenantPrivateRoutes />
          </ErrorBoundary>
        </Route>
      )}
      {isLandlord && (
        <Route path={["/landlord", "/propietary"]}>
          <LandlordPrivateRoutes />
        </Route>
      )}
      {!isLandlord && !isTenant && (
        <Route path={["/landlord", "/propietary", "/tenant"]}>
          <Fallback />
        </Route>
      )}
      <UserCountryCorrectionDialog />
      <HelpWidget />
    </>
  );
};

export default MainPrivateRoutes;
