import InformationBase from "apps/lead/pages/EvaluatorForm/components/EvaluationSummary/components/InformationBase";
import { classes } from "apps/lead/pages/EvaluatorForm/components/EvaluationSummary/components/InitEvaluation/InitEvaluationStyles";
import useInitEvaluation from "apps/lead/pages/EvaluatorForm/components/EvaluationSummary/hooks/useInitEvaluation";
import MemberCardInit from "apps/lead/pages/EvaluatorForm/components/EvaluationSummary/components/MemberCardInit";
import ExtraMembers from "apps/lead/pages/EvaluatorForm/components/EvaluationSummary/components/ExtraMembers";
import { MemberRoleEnum } from "apps/lead/models/Evaluation";

const InitEvaluation = () => {
  const { showInformation, mainMemberForm, extraMembersToRender } =
    useInitEvaluation();

  return (
    <>
      {showInformation && (
        <InformationBase
          informationText="Es necesario completar la información del postulante incompleto
            poder agregar a otra persona."
        />
      )}
      <div className={classes.initEvaluationCardsContainer}>
        <MemberCardInit
          member={mainMemberForm}
          memberRole={MemberRoleEnum.MAIN}
        />
        <ExtraMembers maxCardsToRender={extraMembersToRender} />
      </div>
    </>
  );
};

export default InitEvaluation;
