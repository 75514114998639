import { isEqual, reduce } from "lodash";
import { getFileData } from "apps/lead/pages/EvaluatorForm/utils/utils";

type IDocuments<T> = {
  [k in keyof T]: FileList[];
};

interface FileData {
  name: string;
  size: number;
  type: string;
}

const getFilesChanged = (
  documentsLoaded: FileData[],
  documentsToLoad: FileData[],
  userFormData: FileList[]
): FileList[] =>
  documentsLoaded.reduce<FileList[]>((diff, _, index) => {
    if (!isEqual(documentsLoaded[index], documentsToLoad[index])) {
      const file = documentsToLoad[index].name;
      const documentToUpload = userFormData.find(
        (fileDoc: FileList) => fileDoc[0].name === file
      );
      diff.push(documentToUpload);
    }
    return diff;
  }, []);

export const getDocumentsToUpload = <T>(
  documentsLoaded: { [k in keyof T]: FileData[] | undefined },
  documentsToLoad: { [k in keyof T]: FileData[] | undefined },
  userFormData: Record<keyof T, FileList[]>
): { [key in keyof T]: FileList[] } => {
  // @ts-ignore
  const documentsDifferent: { [k in keyof T]: FileList[] } = reduce(
    documentsLoaded,
    (result, value, key) => {
      if (isEqual(value, documentsToLoad[key])) return result;
      const diff = getFilesChanged(
        value,
        documentsToLoad[key],
        userFormData[key]
      );
      return { ...result, [key]: diff };
    },
    []
  );

  return documentsDifferent;
};

export const parseFilesToCompare = <T>(documents: IDocuments<T>) => {
  if (!documents) return undefined;
  const transformedObj = Object.entries(documents).reduce(
    (acc, [key, value]) => {
      if (value) {
        const arrayOfFiles = value as FileList[];
        acc[key] = arrayOfFiles.map(getFileData);
      } else acc[key] = undefined;
      return acc;
    },
    {} as { [key in keyof T]: FileData[] }
  );
  return transformedObj;
};
