import { LocaleCountries } from "models/Countries";
import { DOCS_REQUIRED_CO } from "./constants";

export const documentsToUpload = {
  [LocaleCountries.CO]: DOCS_REQUIRED_CO,
  [LocaleCountries.CL]: DOCS_REQUIRED_CO,
  [LocaleCountries.MX]: DOCS_REQUIRED_CO,
};

export const filterKey = (
  obj: { [x: string]: FileList },
  keyToFilter: string
) =>
  Object.keys(obj).reduce((result, key) => {
    if (key !== keyToFilter) {
      result[key] = obj[key];
    }
    return result;
  }, {} as { [x: string]: FileList });

export const getDocumentsArray = (obj: { [x: string]: FileList }) =>
  Object.keys(obj).reduce((result, key) => {
    result.push(obj[key]);
    return result;
  }, [] as FileList[]);
