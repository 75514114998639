import { MAX_EVALUATION_MEMBERS } from "apps/lead/pages/EvaluatorForm/components/EvaluationSummary/utils/constants";
import { useEvaluation } from "apps/lead/context/evaluatorContext/EvaluatorContext";
import { UserApplicant } from "apps/lead/context/evaluatorContext/EvaluatorTypes";
import { MemberRoleEnum } from "apps/lead/models/Evaluation";

const useExtraMembers = () => {
  const {
    state: { country, evaluation, applicants },
  } = useEvaluation();

  const getIsMemberDisabled = (
    id: number,
    customApplicants: Partial<UserApplicant>[]
  ) => {
    if (!isMainSummaryCompleted) return true;
    const prevApplicantIsComplete = applicants.filter(
      (applicant) => !applicant.isCompleted
    );
    if (prevApplicantIsComplete.length) return true;
    if (customApplicants[id]) return false;
    if (applicants.length >= MAX_EVALUATION_MEMBERS) return true;
    if (id === 0) return false;
    const preCustomApplicantIsComplete =
      !!customApplicants[id - 1]?.isCompleted;
    return !preCustomApplicantIsComplete;
  };

  const mainMemberSummary = evaluation.members.find(
    (applicant) => applicant.role === MemberRoleEnum.MAIN
  );
  const isMainSummaryCompleted = !!mainMemberSummary?.isFormCompleted;
  const applicantsCodebtor = applicants.filter(
    (applicant) => applicant.extraInformation?.role === MemberRoleEnum.CODEBTOR
  );
  const applicantsComplement = applicants.filter(
    (applicant) =>
      applicant.extraInformation?.role === MemberRoleEnum.COMPLEMENT
  );
  return {
    applicantsCodebtor,
    applicantsComplement,
    getIsMemberDisabled,
    country,
    evaluationStatus: evaluation.status,
  };
};

export default useExtraMembers;
