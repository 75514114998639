import { ReactNode } from "react";

import { cn } from "@houm-com/ui/utils";
import { IoPersonAddOutline, IoPersonOutline } from "@houm-com/ui-icons";

import { classes } from "apps/lead/pages/EvaluatorForm/components/EvaluationSummary/components/MemberCardBase/MemberCardBaseStyles";
import { memberTypeInfo } from "apps/lead/pages/EvaluatorForm/components/EvaluationSummary/components/MemberCardSummary/utils/constants";
import { MemberRole } from "apps/lead/models/Evaluation";

interface Props {
  isDisabled: boolean;
  role: MemberRole;
  children: ReactNode;
  isHovered?: boolean;
  isMember: boolean;
  statusTag?: ReactNode;
}

const MemberCardBase = ({
  isDisabled,
  role,
  children,
  isHovered = true,
  isMember,
  statusTag,
}: Props) => (
  <div
    className={cn(classes.memberCardBaseRoot, {
      [classes.memberCardBaseDisabled]: isDisabled,
      [classes.memberCardBaseHover]: !isDisabled && isHovered,
    })}
  >
    <div className={classes.memberCardBaseStatusTagContainer}>
      {statusTag && statusTag}
    </div>
    <div className={classes.memberCardBaseHeader}>
      <div>
        {isMember ? (
          <IoPersonOutline size={24} />
        ) : (
          <IoPersonAddOutline size={24} />
        )}
      </div>
      <h1 className={classes.memberCardBaseTitle}>
        {memberTypeInfo[role].title}
      </h1>
      {!isMember && (
        <p className={classes.memberCardBaseRequiredLabel}>
          {memberTypeInfo[role].required ? "Obligatorio" : "Opcional"}
        </p>
      )}
    </div>
    {children}
  </div>
);

export default MemberCardBase;
