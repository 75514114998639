export const transformToFileListArray = <T>(
  documentList: T
): Record<keyof T, FileList[]> => {
  const result: Record<keyof T, FileList[]> = {} as Record<keyof T, FileList[]>;
  for (const key in documentList) {
    if (Object.prototype.hasOwnProperty.call(documentList, key)) {
      const nestedObj = documentList[key];
      const fileLists = Object.values(nestedObj);
      result[key] = fileLists;
    }
  }
  return result;
};

export const reverseTransformFileListArray = <
  T extends Partial<Record<keyof T, FileList[]>>
>(
  input: T
): Partial<Record<keyof T, Record<string, FileList>>> => {
  const result: Partial<Record<keyof T, Record<string, FileList>>> = {};

  for (const key in input) {
    if (Object.prototype.hasOwnProperty.call(input, key)) {
      const fileListArray = input[key];
      if (fileListArray) {
        const transformedObj: Record<string, FileList> = {};

        fileListArray.forEach((fileList, index) => {
          transformedObj[`${key}${index + 1}`] = fileList;
        });

        result[key] = transformedObj;
      }
    }
  }

  return result;
};
