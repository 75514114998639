import Button from "@houm-com/ui/Button";

import { classes } from "apps/lead/pages/EvaluatorForm/components/EvaluationSummary/EvaluationSummaryStyles";
import { useEvaluation as useEvaluationContext } from "apps/lead/context/evaluatorContext/EvaluatorContext";
import Header from "apps/lead/components/EvaluatorForm/HeaderForm/Header";
import EvaluatorLayout from "apps/lead/layout/EvaluatorLayout";
import Summary from "apps/lead/pages/EvaluatorForm/components/EvaluationSummary/components/Summary";
import { EvaluationStatusEnum } from "apps/lead/models/Evaluation";
import InitEvaluation from "apps/lead/pages/EvaluatorForm/components/EvaluationSummary/components/InitEvaluation";
import useEvaluationSummary from "apps/lead/pages/EvaluatorForm/components/EvaluationSummary/hooks/useEvaluationSummary";
import useSubmitEvaluation from "apps/lead/pages/EvaluatorForm/hooks/useSubmitEvaluation";
import InformationBase from "apps/lead/pages/EvaluatorForm/components/EvaluationSummary/components/InformationBase";

const EvaluationSummary = () => {
  const {
    state: { applicants, evaluation, country },
  } = useEvaluationContext();
  const { isMainFormCompleted, isEveryoneCompleted } = useEvaluationSummary();
  const isButtonDisabled =
    (applicants.length && !isEveryoneCompleted) || !isMainFormCompleted;

  const { isLoading, mutate } = useSubmitEvaluation({
    evaluationId: evaluation?.id,
    country,
  });
  const onCreateEvaluation = () => mutate();

  return (
    <EvaluatorLayout>
      <div className={classes.evaluationSummaryRoot}>
        <Header title="Información personal" stepTitle="Resumen" />
        <div className={classes.evaluationSummaryContainer}>
          {evaluation?.status === EvaluationStatusEnum.INCOMPLETE ? (
            <InitEvaluation />
          ) : (
            <Summary />
          )}
          {evaluation?.status === EvaluationStatusEnum.INCOMPLETE && (
            <div className={classes.evaluationSummaryButtonContainer}>
              {isButtonDisabled && (
                <InformationBase
                  informationText="Es necesario que la información de cada postulante esté
                    completa para poder enviar la solicitud."
                />
              )}
              <Button
                size="lg"
                disabled={isButtonDisabled}
                variant="primary"
                onClick={onCreateEvaluation}
                isLoading={isLoading}
              >
                Enviar solicitud
              </Button>
            </div>
          )}
        </div>
      </div>
    </EvaluatorLayout>
  );
};

export default EvaluationSummary;
