import { AxiosResponse } from "axios";
import qs from "query-string";

import { ISchedules, PropertyData } from "models/Property";
import { Paginate } from "models/ResponseWithPaginate";
import { createHttp } from "services/helper";
import { BACKEND_URL } from "env";

import {
  ChangePropertyStatusArgs,
  ChangePropertyStatusOperationType,
  ChangePropertyStatusReasonsResponse,
  EditPropertyDetails,
  GetChangePropertyStatusReasonsArgs,
  InspectionReportShowResponse,
  IProperty,
  MainPropertyPropertyAnalysis,
  PropertyDetails,
  PropertyInspectionReportsTableFilters,
  PropertyListResponse,
  PropertySettingUpMissingData,
  SalesDetails,
  SimilarExternalProperty,
  SimilarProperty,
} from "./type";

const qsConfig = { skipEmptyString: true, skipNull: true };

export default {
  searchProperty(
    value: string,
    token: string,
    params = {}
  ): Promise<AxiosResponse<Paginate<IProperty>>> {
    return createHttp(token).get(
      `/propietary/v2/properties/?search=${value}&${qs.stringify(params)}`
    );
  },
  getLandlordPropertiesByToken(
    token: string
  ): Promise<AxiosResponse<Paginate<PropertyListResponse>>> {
    return createHttp(token).get("/propietary/v2/properties/");
  },
  getPropertyInspectionReports(
    propertyId: number,
    // eslint-disable-next-line default-param-last
    pageSize: number = 15,
    // eslint-disable-next-line default-param-last
    page: number = 1,
    { ...filters }: PropertyInspectionReportsTableFilters,
    token: string
  ): Promise<AxiosResponse<Paginate<InspectionReportShowResponse>>> {
    return createHttp(token).get(
      `/properties/v2/${propertyId}/inspection_reports/?page_size=${pageSize}&page=${page}&${qs.stringify(
        filters
      )}`
    );
  },
  getInspectionReportById(
    id: number | "last_inspection_report",
    propertyId: number | string,
    token: string
  ): Promise<AxiosResponse<InspectionReportShowResponse>> {
    return createHttp(token).get(
      `/properties/v2/${propertyId}/inspection_reports/${id}/`
    );
  },
  unpublishPropertyById({
    propertyId,
    reasonId,
    observation,
    token,
  }: ChangePropertyStatusArgs) {
    return createHttp(token, BACKEND_URL).post(
      `/properties/v2/${propertyId}/unpublish_prop/`,
      {
        reason_id: reasonId,
        observation,
      }
    );
  },
  publishPropertyById({
    propertyId,
    reasonId,
    observation,
    token,
  }: ChangePropertyStatusArgs) {
    return createHttp(token, BACKEND_URL).post(
      `/properties/v2/${propertyId}/publish_prop`,
      {
        reason_id: reasonId,
        observation,
      }
    );
  },
  getOperationReasons({
    token,
    businessType,
    operationType = ChangePropertyStatusOperationType.UNPUBLISH,
  }: GetChangePropertyStatusReasonsArgs): ChangePropertyStatusReasonsResponse {
    return createHttp(token, BACKEND_URL).get(
      `/properties/v2/status_change_reasons?${qs.stringify({
        business_type: businessType,
        type: operationType,
      })}`
    );
  },
  getScheduledPhotos(
    propertyUid: string,
    token: string
  ): Promise<AxiosResponse<ISchedules[]>> {
    return createHttp(token).get(
      `/propietary/v2/properties/${propertyUid}/photo_scheduled/`
    );
  },
  getLandlordPropertyRev(
    id: number,
    token: string
  ): Promise<AxiosResponse<MainPropertyPropertyAnalysis>> {
    return createHttp(token).get(`/properties/v2/${id}/stats`);
  },
  // get landlord similar properties
  getLandlordSimilarProperties(
    id: number,
    type: "rent" | "sale",
    token: string
  ): Promise<AxiosResponse<SimilarProperty[]>> {
    //Query params type: rent or sale, max_distance_treshold: max number of similar properties, top_k: max distance to consider similar properties
    const filters = {
      type,
      top_k: 4,
      max_distance_treshold: 25,
    };
    return createHttp(token).get(
      `/properties/v2/${id}/similar-properties-prices?${qs.stringify(
        filters,
        qsConfig
      )}`
    );
  },
  // get landlord similar external properties
  getLandlordSimilarExternalProperties(
    id: number,
    type: "rent" | "sale",
    token: string
  ): Promise<AxiosResponse<SimilarExternalProperty[]>> {
    //Query params type: rent or sale, max_distance_treshold: max number of similar properties, top_k: max distance to consider similar properties
    const filters = {
      type,
      top_k: 4,
      max_distance_treshold: 25,
    };
    return createHttp(token).get(
      `/properties/v2/${id}/similar-external-properties-prices?${qs.stringify(
        filters,
        qsConfig
      )}`
    );
  },

  updateDetails(id: number, data: Partial<PropertyDetails>, token: string) {
    return createHttp(token).put(
      `/propietary/v2/property_details/${id}/`,
      data
    );
  },
  updateSalesDetails(id: number, data: Partial<SalesDetails>, token: string) {
    return createHttp(token).patch(`/properties/v2/sales_details/${id}/`, data);
  },
  get(id: string, token: string): Promise<AxiosResponse<PropertyData>> {
    return createHttp(token).get(`/propietary/v2/properties/${id}/`);
  },
  getSettingUpMissingData(
    uuid: string,
    token: string
  ): Promise<AxiosResponse<PropertySettingUpMissingData>> {
    return createHttp(token, BACKEND_URL).get(
      `/propietary/v2/properties/${uuid}/missing_data/`
    );
  },
  editPropertyDetails(uuid: string, data: EditPropertyDetails, token: string) {
    return createHttp(token).put(
      `/propietary/v2/properties/${uuid}/landlord_property/`,
      data
    );
  },
  endContractHoum: (
    propertyUid: string,
    token: string
  ): Promise<AxiosResponse<any>> =>
    createHttp(token, BACKEND_URL).get(
      `propietary/v2/properties/${propertyUid}/properties/`
    ),
};
