import { useEffect } from "react";
import { useMutation } from "react-query";

import { alert } from "@houm-com/ui/Alert";

import evaluationService from "apps/lead/services/EvaluationService";
import { Locale } from "models/Countries";
import { useEvaluation } from "apps/lead/context/evaluatorContext/EvaluatorContext";
import { setPage } from "apps/lead/context/evaluatorContext/EvaluatorActions";
import { EvaluatorPages } from "apps/lead/context/evaluatorContext/EvaluatorTypes";

interface Props {
  evaluationId: string;
  country: Locale;
}
const useSubmitEvaluation = ({ evaluationId, country }: Props) => {
  const { dispatch } = useEvaluation();
  const queryMutate = useMutation(async () =>
    evaluationService.startEvaluationProcess(evaluationId, country)
  );

  useEffect(() => {
    if (queryMutate.isError)
      alert.error({
        message: "¡Ocurrió un error!",
        disclaimer: "Por favor, intenta de nuevo más tarde",
      });
  }, [queryMutate.isError]);

  useEffect(() => {
    if (queryMutate.isSuccess) dispatch(setPage(EvaluatorPages.FINISH));
  }, [queryMutate.isSuccess]);

  return queryMutate;
};

export default useSubmitEvaluation;
