import { Trans, useTranslation } from "react-i18next";

import clx from "classnames";
import { chunk } from "lodash";

import { Grid, Typography } from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";

import HoumLogo from "assets/img/OUI).svg";
import usePlanCardBenefitsStyles from "./PlanCardBenefitsStyles";

interface Props {
  isHorizontal?: boolean;
  isMainPlan?: boolean;
  benefitsList: string[];
  isCustom?: boolean;
}

export default function PlanCardBenefits({
  isHorizontal,
  isMainPlan,
  benefitsList,
  isCustom,
}: Props) {
  const { t } = useTranslation("landlord.property.plans");
  const BENEFITS_CHUCK_SIZE = isMainPlan ? 2 : 3;
  const classes = usePlanCardBenefitsStyles({ isHorizontal, isMainPlan });

  const renderBenefits = (list: string[]) => {
    const chunkedList = chunk(list, BENEFITS_CHUCK_SIZE);
    return chunkedList.map((chunked) => (
      <div key={`chunk-${chunked[0]}`} className={classes.benefitsChunk}>
        {chunked.map((listItemText) => (
          <Grid
            key={`listItem-${listItemText}`}
            item
            className={classes.benefitItem}
          >
            <Typography className={classes.listItemText}>
              <CheckCircle
                className={clx(classes.listIcon, classes.listIcon_check)}
              />
              {listItemText}
            </Typography>
          </Grid>
        ))}
      </div>
    ));
  };

  const renderCustom = () => (
    <div className={classes.contactUsContainer}>
      <div className={classes.houmLogo}>
        <img src={HoumLogo} alt="houm-logo" className="max-w-[74px]" />
      </div>
      <Typography>
        <Trans
          i18nKey={t("CONTACT_EMAIL_INFO")}
          components={{
            1: <strong className={classes.email} />,
          }}
        />
      </Typography>
    </div>
  );
  return (
    <Grid spacing={1} container className={classes.benefitsContainer}>
      {isCustom ? renderCustom() : renderBenefits(benefitsList)}
    </Grid>
  );
}
