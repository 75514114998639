import { classes } from "apps/lead/pages/EvaluatorForm/components/EvaluationSummary/components/InformationBase/InformationBaseStyles";

interface Props {
  informationText: string;
}

const InformationBase = ({ informationText }: Props) => (
  <div className={classes.informationBaseContainer}>
    <p className={classes.informationBaseText}>{informationText}</p>
  </div>
);

export default InformationBase;
