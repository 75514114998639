import { useEffect } from "react";
import { isEqual } from "lodash";

import useUpdateDocuments from "apps/lead/pages/EvaluatorForm/hooks/useUpdateDocuments";
import { useEvaluation } from "apps/lead/context/evaluatorContext/EvaluatorContext";
import { setUserInformation } from "apps/lead/context/evaluatorContext/EvaluatorActions";
import {
  parseFilesToCompare,
  getDocumentsToUpload,
} from "apps/lead/pages/EvaluatorForm/hooks/useDocumentFiles/utils/parsers";
import { DocumentObjectTypeEnum } from "apps/lead/context/evaluatorContext/EvaluatorTypes";
import { resetFilesData } from "apps/lead/pages/EvaluatorForm/hooks/useDocumentFiles/utils/utils";

interface Props {
  documentType: DocumentObjectTypeEnum;
  onSuccess?: () => void;
}

const useDocumentFiles = <T>({ documentType, onSuccess }: Props) => {
  const {
    state: { user: userInformation, country },
    dispatch,
  } = useEvaluation();

  const { isLoading, mutate, isSuccess } = useUpdateDocuments({
    memberId: userInformation.memberId,
    country,
  });
  const uploadDocuments = async (data: Record<keyof T, FileList[]>) => {
    if (isLoading) return;
    dispatch(
      setUserInformation({
        ...userInformation,
        [documentType]: {
          ...resetFilesData<T>(),
          ...data,
        },
      })
    );
    const parsedCurrentFiles = parseFilesToCompare(
      userInformation[documentType] as Record<keyof T, FileList[]>
    );
    const parsedFormData = parseFilesToCompare<T>({
      ...userInformation[documentType],
      ...data,
    });
    const areDocumentsEqual = isEqual(parsedCurrentFiles, parsedFormData);
    if (areDocumentsEqual) {
      onSuccess?.();
    } else if (parsedCurrentFiles === undefined) mutate(data);
    else {
      const documents = getDocumentsToUpload<T>(
        parsedCurrentFiles,
        parsedFormData,
        data
      );
      mutate(documents);
    }
  };

  useEffect(() => {
    if (isSuccess) onSuccess?.();
  }, [isSuccess]);

  return {
    uploadDocuments,
    isLoading,
  };
};

export default useDocumentFiles;
