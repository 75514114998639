import DependentForm from "apps/lead/pages/EvaluatorForm/components/EmploymentValidationForm/components/co/DependentForm";
import IndependentForm from "apps/lead/pages/EvaluatorForm/components/EmploymentValidationForm/components/co/IndependentForm";
import RetiredForm from "apps/lead/pages/EvaluatorForm/components/EmploymentValidationForm/components/co/RetiredForm";
import Header from "apps/lead/components/EvaluatorForm/HeaderForm/Header";
import FormWrapper from "apps/lead/components/EvaluatorForm/FormWrapper";
import { useEvaluation } from "apps/lead/context/evaluatorContext/EvaluatorContext";
import useDocumentFiles from "apps/lead/pages/EvaluatorForm/hooks/useDocumentFiles";
import { DocumentObjectTypeEnum } from "apps/lead/context/evaluatorContext/EvaluatorTypes";
import { EmploymentValidationTypes } from "apps/lead/pages/EvaluatorForm/components/EmploymentValidationForm/utils/types";
import { setActiveStep } from "apps/lead/context/evaluatorContext/EvaluatorActions";
import { IncomeSourceTypeEnum } from "apps/lead/models/Evaluation";

const EmploymentValidationForm = () => {
  const {
    state: {
      user: { employment },
      activeStep,
    },
    dispatch,
  } = useEvaluation();

  const { uploadDocuments, isLoading } = useDocumentFiles<
    EmploymentValidationTypes<typeof employment.workerType>
  >({
    documentType: DocumentObjectTypeEnum.EMPLOYMENT,
    onSuccess: () => dispatch(setActiveStep(activeStep + 1)),
  });

  function onUploadFiles<
    T extends keyof EmploymentValidationTypes<typeof employment.workerType>
  >(data: Record<T, FileList[]>) {
    uploadDocuments(data);
  }

  return (
    <>
      <Header
        title="Validación laboral"
        subtitle="Estos documentos pueden estar en formato PDF o como imagen (JPEG, JPG o PNG)"
        stepTitle="Validación laboral e ingresos"
      />
      <FormWrapper classNameFormWrapper="flex flex-col">
        {employment?.workerType === IncomeSourceTypeEnum.INDEPENDENT && (
          <IndependentForm
            onUploadFiles={(data) => onUploadFiles(data)}
            isLoading={isLoading}
          />
        )}
        {employment?.workerType === IncomeSourceTypeEnum.DEPENDENT && (
          <DependentForm
            onUploadFiles={(data) => onUploadFiles(data)}
            isLoading={isLoading}
          />
        )}
        {employment?.workerType === IncomeSourceTypeEnum.RETIRED && (
          <RetiredForm
            onUploadFiles={(data) => onUploadFiles(data)}
            isLoading={isLoading}
          />
        )}
      </FormWrapper>
    </>
  );
};

export default EmploymentValidationForm;
