import { FormProvider, SubmitHandler } from "react-hook-form";

import FieldInputFile from "@houm-com/ui/FieldInputFile";

import { dependentFilesSchema } from "apps/lead/pages/EvaluatorForm/components/EmploymentValidationForm/components/co/DependentForm/utils/validationSchema";
import { IDependentFilesForm } from "apps/lead/pages/EvaluatorForm/components/EmploymentValidationForm/components/co/DependentForm/utils/types";
import { classes } from "apps/lead/pages/EvaluatorForm/components/EmploymentValidationForm/components/co/DependentForm/DependentFormStyles";
import { useEvaluation } from "apps/lead/context/evaluatorContext/EvaluatorContext";
import CallToAction from "apps/lead/components/EvaluatorForm/CallToAction";
import FormWrapper from "apps/lead/components/EvaluatorForm/FormWrapper";
import useReactHookForm from "hooks/useReactHookForm";
import {
  parserMutationFiles,
  parserDefaultData,
} from "apps/lead/pages/EvaluatorForm/components/EmploymentValidationForm/components/co/DependentForm/utils/parsers";
import { DocumentFileTypeEnum } from "apps/lead/context/evaluatorContext/EvaluatorTypes";
import { ALLOWED_MIME_TYPES } from "apps/lead/pages/EvaluatorForm/utils/constants";

interface Props {
  onUploadFiles: (data: Record<keyof IDependentFilesForm, FileList[]>) => void;
  isLoading: boolean;
}

const DependentForm = ({ onUploadFiles, isLoading }: Props) => {
  const {
    state: { user: userInformation },
  } = useEvaluation();

  const defaultValues = parserDefaultData(userInformation.employmentFiles);
  const { methods, errors } = useReactHookForm<IDependentFilesForm>({
    formSchema: dependentFilesSchema,
    formOptions: {
      defaultValues,
    },
  });

  const onSubmit: SubmitHandler<IDependentFilesForm> = (data) => {
    if (isLoading) return;
    const transformedData = parserMutationFiles(data);
    onUploadFiles(transformedData);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormWrapper classNameFormWrapper={classes.dependentFormRoot}>
          <FieldInputFile
            id={DocumentFileTypeEnum.EMPLOYMENT_CERTIFICATE}
            label="Contrato o certificado laboral, en donde se especifique el tipo de contrato"
            allowedMimeTypes={ALLOWED_MIME_TYPES}
            classNameInputButton={classes.dependentFormInputFile}
            splitLength={40}
            required
            form="novalidateform"
            file={defaultValues[DocumentFileTypeEnum.EMPLOYMENT_CERTIFICATE]}
            errorMessage={
              errors[DocumentFileTypeEnum.EMPLOYMENT_CERTIFICATE]?.message
            }
            {...methods.register(DocumentFileTypeEnum.EMPLOYMENT_CERTIFICATE)}
          />
        </FormWrapper>
        <CallToAction label="Continuar" isLoading={isLoading} />
      </form>
    </FormProvider>
  );
};

export default DependentForm;
