import { useEvaluation } from "apps/lead/context/evaluatorContext/EvaluatorContext";
import {
  EvaluationStatusEnum,
  StatusMemberEnum,
} from "apps/lead/models/Evaluation";
import { MAX_EVALUATION_MEMBERS } from "apps/lead/pages/EvaluatorForm/components/EvaluationSummary/utils/constants";

const useSummary = () => {
  const {
    state: { evaluation, applicants },
  } = useEvaluation();

  const membersCompleted = evaluation.members.filter(
    (member) => member.isFormCompleted
  );

  const applicantsIncomplete = evaluation.members.filter(
    (applicant) => !applicant.isFormCompleted
  );

  const evalApplicantsNotRejected = evaluation
    ? membersCompleted.filter(
        (member) =>
          member.status !== StatusMemberEnum.REJECTED &&
          member.status !== StatusMemberEnum.DESISTED
      )
    : [];
  const membersToRender =
    MAX_EVALUATION_MEMBERS -
    (applicants.length + evalApplicantsNotRejected.length);

  const showIncompleteApplicantInfo =
    evaluation.status !== EvaluationStatusEnum.APPROVED &&
    applicantsIncomplete.length > 0 &&
    membersToRender > 1;

  return {
    membersCompleted,
    membersToRender,
    showIncompleteApplicantInfo,
  };
};

export default useSummary;
