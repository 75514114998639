import { useEffect } from "react";
import { FormProvider, SubmitHandler } from "react-hook-form";

import Spinner from "@houm-com/ui/Spinner";

import { userFormSchema } from "apps/lead/pages/EvaluatorForm/components/ApplicantForm/utils/validationSchema";
import { getParseApplicant } from "apps/lead/pages/EvaluatorForm/components/ApplicantForm/utils/parsers";
import { getDefaultValues } from "apps/lead/pages/EvaluatorForm/components/ApplicantForm/utils/utils";
import { classes } from "apps/lead/pages/EvaluatorForm/components/ApplicantForm/ApplicantFormStyles";
import { IApplicantForm } from "apps/lead/pages/EvaluatorForm/components/ApplicantForm/utils/types";
import {
  setPage,
  setUserInformation,
} from "apps/lead/context/evaluatorContext/EvaluatorActions";
import FormWrapper from "apps/lead/components/EvaluatorForm/FormWrapper/FormWrapper";
import { useEvaluation } from "apps/lead/context/evaluatorContext/EvaluatorContext";
import { EvaluatorPages } from "apps/lead/context/evaluatorContext/EvaluatorTypes";
import ApplicantFields from "apps/lead/components/EvaluatorForm/ApplicantFields";
import CallToAction from "apps/lead/components/EvaluatorForm/CallToAction";
import Header from "apps/lead/components/EvaluatorForm/HeaderForm/Header";
import useDocumentTypeListPublic from "hooks/useDocumentTypeListPublic";
import useReactHookForm from "hooks/useReactHookForm";
import useApplicantFormSubmit from "apps/lead/pages/EvaluatorForm/components/ApplicantForm/hooks/useApplicantFormSubmit";
import useCheckApplicantChanges from "apps/lead/pages/EvaluatorForm/components/ApplicantForm/hooks/useCheckApplicantChanges";

const ApplicantForm = () => {
  const {
    state: { applicants, user: userInformation, country, evaluation },
    dispatch,
  } = useEvaluation();
  const {
    createResponse,
    isLoading: isLoadingSubmit,
    isSuccess: isSuccessSubmit,
    onSubmitFormData,
  } = useApplicantFormSubmit({
    country,
    evaluationId: evaluation.id,
    memberId: userInformation?.memberId,
    memberRole: userInformation.extraInformation?.role,
  });
  const { checkChanges } = useCheckApplicantChanges();

  const userList = applicants.filter(
    (applicant) => applicant.user?.email !== userInformation?.user?.email
  );
  const emailList = userList.map((applicant) => applicant.user.email);

  const { data, isSuccess, isError, isLoading } =
    useDocumentTypeListPublic(country);
  const { methods } = useReactHookForm<IApplicantForm>({
    formSchema: userFormSchema(data, emailList),
    formOptions: {
      defaultValues: getDefaultValues(userInformation?.user, country),
    },
  });

  const onSubmit: SubmitHandler<IApplicantForm> = (data) => {
    if (isLoadingSubmit) return;
    const parsedData = getParseApplicant(data);
    const isDataEqual = checkChanges(parsedData);
    dispatch(
      setUserInformation({
        ...userInformation,
        user: {
          ...userInformation.user,
          ...parsedData,
        },
      })
    );
    if (isDataEqual) {
      dispatch(setPage(EvaluatorPages.SELFIE_DISCLAIMER));
    } else {
      onSubmitFormData(parsedData);
    }
  };

  useEffect(() => {
    if (isSuccessSubmit) {
      dispatch(
        setUserInformation({
          memberId: createResponse
            ? createResponse.id
            : userInformation.memberId,
          ...userInformation,
        })
      );
      dispatch(setPage(EvaluatorPages.SELFIE_DISCLAIMER));
    }
  }, [isSuccessSubmit]);

  return (
    <FormProvider {...methods}>
      <Header title="Información básica" />
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormWrapper classNameFormWrapper={classes.applicantFormWrapper}>
          {isError && (
            <p className={classes.applicantFormErrorText}>
              Ocurrió un error intentando cargar formulario
            </p>
          )}
          {isLoading && (
            <Spinner
              variant="primary"
              size="xl"
              classNameContainer={classes.applicantFormErrorText}
            />
          )}
          {isSuccess && (
            <ApplicantFields country={country} documentList={data} />
          )}
        </FormWrapper>
        <CallToAction isLoading={isLoadingSubmit} />
      </form>
    </FormProvider>
  );
};

export default ApplicantForm;
