import { useQuery, UseQueryResult } from "react-query";
import { AxiosError } from "axios";

import documentTypeService from "services/documentTypeService";
import { localeToCountry } from "utils/countryCode";
import { DocumentType } from "models/Document";
import { Locale } from "models/Countries";
import { useGetToken } from "hooks";

export type DocumentTypes = {
  value: number;
  identifier: string;
  label: string;
  regex: string;
};

const useDocumentTypeList = (country: Locale) => {
  const { getToken } = useGetToken();

  const {
    isLoading,
    error,
    data,
    isError,
  }: UseQueryResult<DocumentTypes[], AxiosError> = useQuery<
    DocumentTypes[],
    AxiosError
  >(
    ["document_type_list", country],
    async () => {
      const authToken = await getToken();
      const response = await documentTypeService.getDocumentListAuth(
        localeToCountry[country],
        authToken
      );
      const docTypes: DocumentType[] = response.data.results;
      const parseDocumentsType = docTypes.map((h: DocumentType) => ({
        value: h.id,
        label: h.type,
        identifier: h.identifier,
        regex: h.validation_regex,
      }));
      return parseDocumentsType;
    },
    {
      enabled: !!country,
    }
  );

  return {
    isLoading,
    error,
    data,
    isError,
  };
};

export default useDocumentTypeList;
