import { useEffect } from "react";
import { useParams } from "react-router-dom";

import UserInformationForm from "apps/lead/pages/EvaluatorForm/components/UserInformationForm";
import SelfieDisclaimer from "apps/lead/pages/EvaluatorForm/components/SelfieDisclaimer";
import { useEvaluation } from "apps/lead/context/evaluatorContext/EvaluatorContext";
import {
  setActiveStep,
  setPage,
  setUserInformation,
  setCountry,
} from "apps/lead/context/evaluatorContext/EvaluatorActions";
import EvaluationSummary from "apps/lead/pages/EvaluatorForm/components/EvaluationSummary";
import UploadDocuments from "apps/lead/pages/EvaluatorForm/components/UploadDocuments";
import { EvaluatorPages } from "apps/lead/context/evaluatorContext/EvaluatorTypes";
import Welcome from "apps/lead/pages/EvaluatorForm/components/Welcome";
import Finish from "apps/lead/pages/EvaluatorForm/components/Finish";
import EvaluatorLayout from "apps/lead/layout/EvaluatorLayout";
import { Locale } from "models/Countries";

const EvaluatorForm = () => {
  const { country } = useParams<{ country: Locale }>();
  const {
    state: { activeStep, page },
    dispatch,
  } = useEvaluation();

  useEffect(() => {
    dispatch(setCountry(country));
  }, []);

  const handleBack = () => {
    if (page === EvaluatorPages.RESUME) {
      dispatch(setPage(EvaluatorPages.WELCOME));
    }
    if (page === EvaluatorPages.FORM) {
      if (activeStep === 0) {
        dispatch(setPage(EvaluatorPages.RESUME));
        dispatch(setActiveStep(0));
        dispatch(setUserInformation(undefined));
      } else dispatch(setActiveStep(activeStep - 1));
    }
    if (page === EvaluatorPages.SELFIE_DISCLAIMER) {
      dispatch(setPage(EvaluatorPages.FORM));
    }
    if (page === EvaluatorPages.DOCUMENTS) {
      dispatch(setPage(EvaluatorPages.RESUME));
    }
  };

  return (
    <EvaluatorLayout handleBack={handleBack}>
      {page === EvaluatorPages.WELCOME && <Welcome />}
      {page === EvaluatorPages.RESUME && <EvaluationSummary />}
      {page === EvaluatorPages.FORM && <UserInformationForm />}
      {page === EvaluatorPages.SELFIE_DISCLAIMER && <SelfieDisclaimer />}
      {page === EvaluatorPages.FINISH && <Finish />}
      {page === EvaluatorPages.DOCUMENTS && <UploadDocuments />}
    </EvaluatorLayout>
  );
};

export default EvaluatorForm;
