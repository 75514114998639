import {
  IncomeSourceType,
  Evaluation,
  MemberRole,
} from "apps/lead/models/Evaluation";
import { Locale } from "models/Countries";

export const SET_ACTIVE_STEP = "SET_ACTIVE_STEP";
export const SET_LOADING = "SET_LOADING";
export const SET_STEPS = "SET_STEPS";
export const SET_USER_INFO = "SET_USER_INFO";
export const SET_APPLICANT = "SET_APPLICANT";
export const SET_PAGE = "SET_PAGE";
export const SET_COUNTRY = "SET_COUNTRY";
export const SET_EVALUATION = "SET_EVALUATION";
export const SET_APPLICANTS = "SET_APPLICANTS";

export enum EvaluatorPages {
  WELCOME = "welcome",
  RESUME = "resume",
  FORM = "form",
  SELFIE_DISCLAIMER = "selfie_disclaimer",
  FINISH = "finish",
  DOCUMENTS = "documents",
}

export interface User {
  id?: number;
  name: string;
  lastName: string;
  secondLastName: string;
  email: string;
  phone: string;
  documentType: number;
  document: string;
  nationality: string;
}

export interface IEmployment {
  workerType: IncomeSourceType;
  occupation: string;
  employer: string;
  employerPhone: string;
}
export enum DocumentFileTypeEnum {
  FRONT_CI = "frontIdentification",
  BACK_CI = "backIdentification",
  SELFIE_CI = "frontIdentificationSelfie",
  BANK_STATEMENT = "bankStatements",
  PENSION_SALARY_SETTLEMENT = "pensionSalarySettlements",
  PENSIONER_CERTIFICATE = "pensionCertificate",
  EMPLOYMENT_CERTIFICATE = "laborCertificate",
  SALARY_SETTLEMENT = "salarySettlements",
  COMPANY_CONSTITUTION = "companyConstitution",
  COMPANY_INCOME_STATEMENT = "companyIncomeStatement",
  TAX_DECLARATION = "taxReturn",
  UNKNOWN = "unknown",
  OTHER = "other",
}

export interface EmploymentFiles {
  [DocumentFileTypeEnum.EMPLOYMENT_CERTIFICATE]?: FileList[];
  [DocumentFileTypeEnum.TAX_DECLARATION]?: FileList[];
  [DocumentFileTypeEnum.COMPANY_INCOME_STATEMENT]?: FileList[];
  [DocumentFileTypeEnum.COMPANY_CONSTITUTION]?: FileList[];
  [DocumentFileTypeEnum.PENSIONER_CERTIFICATE]?: FileList[];
}

export interface IdentityFiles {
  [DocumentFileTypeEnum.FRONT_CI]: FileList[];
  [DocumentFileTypeEnum.BACK_CI]: FileList[];
  [DocumentFileTypeEnum.SELFIE_CI]: FileList[];
}

export enum DocumentObjectTypeEnum {
  IDENTITY = "identityFiles",
  EMPLOYMENT = "employmentFiles",
  INCOME_VALIDATION = "incomeValidationFiles",
}
export interface IncomeValidationFiles {
  [DocumentFileTypeEnum.PENSION_SALARY_SETTLEMENT]?: FileList[];
  [DocumentFileTypeEnum.SALARY_SETTLEMENT]?: FileList[];
  [DocumentFileTypeEnum.BANK_STATEMENT]: FileList[];
}

export interface UserApplicant {
  memberId?: string;
  user: User;
  identityFiles: IdentityFiles;
  employmentFiles: EmploymentFiles;
  incomeValidationFiles: IncomeValidationFiles;
  employment: IEmployment;
  extraInformation: {
    fullAddress?: string;
    role: MemberRole;
  };
  isCompleted: boolean;
}

interface setLoading {
  type: typeof SET_LOADING;
  loading: boolean;
}

interface setActiveStep {
  type: typeof SET_ACTIVE_STEP;
  activeStep: number;
}

interface setSteps {
  type: typeof SET_STEPS;
  steps: number;
}

interface setUserInformation {
  type: typeof SET_USER_INFO;
  userInfo: Partial<UserApplicant>;
}

interface setApplicant {
  type: typeof SET_APPLICANT;
  applicant: Partial<UserApplicant>;
}

interface setApplicants {
  type: typeof SET_APPLICANTS;
  applicants: Partial<UserApplicant>[];
}

interface setPage {
  type: typeof SET_PAGE;
  page: EvaluatorPages;
}

interface setCountry {
  type: typeof SET_COUNTRY;
  country: Locale;
}

interface setEvaluation {
  type: typeof SET_EVALUATION;
  evaluation: Evaluation;
}

export interface State {
  loading: boolean;
  activeStep: number;
  steps: number;
  applicants: Partial<UserApplicant>[];
  user: Partial<UserApplicant>;
  page: EvaluatorPages;
  country: Locale;
  evaluation: Evaluation;
}

export type Action =
  | setLoading
  | setActiveStep
  | setSteps
  | setApplicant
  | setUserInformation
  | setPage
  | setCountry
  | setEvaluation
  | setApplicants;

export type Dispatch = (action: Action) => void;
