import { MemberPersonalInformationForm } from "apps/lead/pages/EvaluatorForm/hooks/useUpdateMemberInformation/utils/types";

export const parserPersonInformation = (
  data: MemberPersonalInformationForm
) => ({
  ...(data?.employer && { employer: data.employer }),
  ...(data?.address && {
    address: {
      full_address: data.address,
    },
  }),
  ...(data?.workerType && { income_source_type: data.workerType }),
  ...(data?.occupation && { occupation: data.occupation }),
  ...(data?.nationality && { nationality: data.nationality }),
  ...(data?.employerPhone && { employer_phone: data.employerPhone }),
});
