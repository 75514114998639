import { isEqual } from "lodash";

import { useEvaluation } from "apps/lead/context/evaluatorContext/EvaluatorContext";
import { User } from "apps/lead/context/evaluatorContext/EvaluatorTypes";

const parserApplicantData = (user: User) => ({
  name: user?.name,
  lastName: user?.lastName,
  secondLastName: user?.secondLastName,
  email: user?.email,
  phone: user?.phone,
  documentType: user?.documentType,
  document: user?.document,
});

const useCheckApplicantChanges = () => {
  const {
    state: { user: userInformation },
  } = useEvaluation();

  const checkChanges = (data: User) => {
    const prevUserData = parserApplicantData(userInformation.user);
    const currentUserFormData = parserApplicantData(data);

    return isEqual(prevUserData, currentUserFormData);
  };

  return {
    checkChanges,
  };
};

export default useCheckApplicantChanges;
