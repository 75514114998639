import {
  EvaluationMemberStatus,
  StatusMemberEnum,
} from "apps/lead/models/Evaluation";

import Tag from "@houm-com/ui/Tag";

import { ComponentProps } from "react";

export const memberTypeInfo = {
  main: {
    title: "Arrendatario principal",
    information: "Eres la persona que quedará como titular del contrato.",
    disclaimer: undefined,
    required: true,
  },
  codebtor: {
    title: "Codeudor",
    information:
      "Persona que demuestra ingresos de al menos 2.5 veces el valor de arriendo. No es necesario que habite en la propiedad.",
    disclaimer: undefined,
    required: false,
  },
  rent_complement: {
    title: "Complemento",
    information:
      "Persona que habitará la propiedad y complementa renta para alcanzar el valor de 2.5 veces del arriendo.",
    disclaimer: undefined,
    required: false,
  },
};
export const EvaluationMemberStatusDictionary: {
  [locale in EvaluationMemberStatus]: string;
} = {
  [StatusMemberEnum.APPROVED]: "Aprobado",
  [StatusMemberEnum.REJECTED]: "Rechazado",
  [StatusMemberEnum.DESISTED]: "Desistida",
  [StatusMemberEnum.COMMERCIALLY_APPROVED]: "Aprobado",
  [StatusMemberEnum.PENDING_DOCS]: "En revisión",
  [StatusMemberEnum.READY_FOR_EVALUATION]: "En revisión",
  [StatusMemberEnum.NEW_DOCS]: "En revisión",
  [StatusMemberEnum.START]: "En revisión",
  [StatusMemberEnum.NO_DOCS]: "En revisión",
};

export const statusVariant: {
  [locale in EvaluationMemberStatus]: ComponentProps<typeof Tag>["variant"];
} = {
  [StatusMemberEnum.COMMERCIALLY_APPROVED]: "success",
  [StatusMemberEnum.DESISTED]: "error",
  [StatusMemberEnum.PENDING_DOCS]: "warning",
  [StatusMemberEnum.READY_FOR_EVALUATION]: "info",
  [StatusMemberEnum.NEW_DOCS]: "warning",
  [StatusMemberEnum.APPROVED]: "success",
  [StatusMemberEnum.REJECTED]: "error",
  [StatusMemberEnum.START]: "default",
  [StatusMemberEnum.NO_DOCS]: "default",
};
