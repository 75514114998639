import { useEffect } from "react";
import { FormProvider, SubmitHandler } from "react-hook-form";

import FieldInput from "@houm-com/ui/FieldInput";

import { employmentSchema } from "apps/lead/pages/EvaluatorForm/components/EmployerForm/utils/validationSchema";
import { parseEmploymentData } from "apps/lead/pages/EvaluatorForm/components/EmployerForm/utils/parsers";
import { IEmploymentForm } from "apps/lead/pages/EvaluatorForm/components/EmployerForm/utils/types";
import { getDefaultData } from "apps/lead/pages/EvaluatorForm/components/EmployerForm/utils/utils";
import { classes } from "apps/lead/pages/EvaluatorForm/components/EmployerForm/EmployerFormStyles";
import FormWrapper from "apps/lead/components/EvaluatorForm/FormWrapper/FormWrapper";
import { useEvaluation } from "apps/lead/context/evaluatorContext/EvaluatorContext";
import CallToAction from "apps/lead/components/EvaluatorForm/CallToAction";
import Header from "apps/lead/components/EvaluatorForm/HeaderForm/Header";
import {
  setActiveStep,
  setUserInformation,
} from "apps/lead/context/evaluatorContext/EvaluatorActions";
import PhoneField from "apps/lead/components/PhoneField";
import useReactHookForm from "hooks/useReactHookForm";
import useUpdateMemberInformation from "apps/lead/pages/EvaluatorForm/hooks/useUpdateMemberInformation";

const EmployerForm = () => {
  const {
    state: { user: userInformation, country, activeStep },
    dispatch,
  } = useEvaluation();
  const { methods, errors } = useReactHookForm<IEmploymentForm>({
    formSchema: employmentSchema,
    formOptions: {
      defaultValues: getDefaultData(userInformation.employment, country),
    },
  });
  const { isLoading, mutate, isSuccess, data } = useUpdateMemberInformation({
    memberId: userInformation?.memberId,
    country,
  });

  const onSubmit: SubmitHandler<IEmploymentForm> = (data) => {
    if (isLoading) return;
    mutate({
      ...data,
      employerPhone: data.phone.parsedPhone,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      const parsedData = parseEmploymentData(userInformation, data);
      dispatch(setUserInformation(parsedData));
      dispatch(setActiveStep(activeStep + 1));
    }
  }, [isSuccess]);

  return (
    <FormProvider {...methods}>
      <Header
        title="Cuéntanos un poco más de ti"
        stepTitle="Información Laboral"
      />
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormWrapper classNameFormWrapper={classes.employerFormWrapper}>
          <FieldInput
            id="occupation"
            label="Cargo que desempeñas"
            placeholder="Director, desarrollador, diseñador, etc."
            required
            errorMessage={errors.occupation?.message}
            form="novalidateform"
            {...methods.register("occupation")}
          />
          <FieldInput
            id="employer"
            label="Nombre de tu empleador"
            placeholder="Nombre de tu empresa"
            required
            errorMessage={errors.employer?.message}
            form="novalidateform"
            {...methods.register("employer")}
          />
          <PhoneField />
        </FormWrapper>
        <CallToAction label="Continuar" isLoading={isLoading} />
      </form>
    </FormProvider>
  );
};

export default EmployerForm;
