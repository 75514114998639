import MemberCardInit from "apps/lead/pages/EvaluatorForm/components/EvaluationSummary/components/MemberCardInit";
import {
  EvaluationStatusEnum,
  MemberRoleEnum,
} from "apps/lead/models/Evaluation";
import { LocaleCountries } from "models/Countries";
import useExtraMembers from "apps/lead/pages/EvaluatorForm/components/EvaluationSummary/hooks/useExtraMembers";

interface Props {
  maxCardsToRender?: number;
}

const ExtraMembers = ({ maxCardsToRender = 2 }: Props) => {
  const {
    applicantsCodebtor,
    applicantsComplement,
    evaluationStatus,
    country,
    getIsMemberDisabled,
  } = useExtraMembers();

  return (
    <>
      {applicantsCodebtor.map((codebtor) => (
        <MemberCardInit
          key={codebtor.memberId}
          member={codebtor}
          memberRole={MemberRoleEnum.CODEBTOR}
        />
      ))}
      {applicantsComplement.map((complement) => (
        <MemberCardInit
          key={complement.memberId}
          member={complement}
          memberRole={MemberRoleEnum.COMPLEMENT}
        />
      ))}
      {evaluationStatus !== EvaluationStatusEnum.APPROVED &&
        maxCardsToRender > 0 && (
          <>
            {[...Array(maxCardsToRender).keys()].map((id) => (
              <MemberCardInit
                isDisabled={getIsMemberDisabled(id, applicantsCodebtor)}
                key={id}
                memberRole={MemberRoleEnum.CODEBTOR}
              />
            ))}
            {country === LocaleCountries.CO &&
              [...Array(maxCardsToRender).keys()].map((id) => (
                <MemberCardInit
                  isDisabled={getIsMemberDisabled(id, applicantsComplement)}
                  key={id}
                  memberRole={MemberRoleEnum.COMPLEMENT}
                />
              ))}
          </>
        )}
    </>
  );
};

export default ExtraMembers;
