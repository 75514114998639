import { IoAddOutline, IoArrowForwardOutline } from "@houm-com/ui-icons";
import LightButton from "@houm-com/ui/LightButton";
import Tag from "@houm-com/ui/Tag";

import MemberCardBase from "apps/lead/pages/EvaluatorForm/components/EvaluationSummary/components/MemberCardBase";
import MemberInformation from "apps/lead/pages/EvaluatorForm/components/EvaluationSummary/components/MemberInformation";
import MemberCardInformation from "apps/lead/pages/EvaluatorForm/components/EvaluationSummary/components/MemberCardInit/components/MemberCardInformation";
import { classes } from "apps/lead/pages/EvaluatorForm/components/EvaluationSummary/components/MemberCardInit/MemberCardInitStyles";
import { MemberRole } from "apps/lead/models/Evaluation";
import { useEvaluation } from "apps/lead/context/evaluatorContext/EvaluatorContext";
import {
  setPage,
  setUserInformation,
} from "apps/lead/context/evaluatorContext/EvaluatorActions";
import {
  EvaluatorPages,
  UserApplicant,
} from "apps/lead/context/evaluatorContext/EvaluatorTypes";

interface Props {
  isDisabled?: boolean;
  memberRole: MemberRole;
  member?: Partial<UserApplicant>;
}

const MemberCardInit = ({ isDisabled = false, memberRole, member }: Props) => {
  const { dispatch } = useEvaluation();
  const onAddMember = () => {
    dispatch(setPage(EvaluatorPages.FORM));
    const userInfo = member || {
      extraInformation: {
        role: memberRole,
      },
      isCompleted: false,
    };
    dispatch(setUserInformation(userInfo));
  };

  return (
    <MemberCardBase
      isMember={!!member}
      isDisabled={isDisabled}
      role={memberRole}
      isHovered={!member?.isCompleted}
      statusTag={
        member && (
          <Tag
            label={member.isCompleted ? "Completo" : "Incompleto"}
            variant={member.isCompleted ? "success" : "error"}
          />
        )
      }
    >
      {member ? (
        <MemberInformation user={member.user} />
      ) : (
        <MemberCardInformation role={memberRole} isDisabled={isDisabled} />
      )}
      {member && !member.isCompleted && (
        <LightButton
          size="lg"
          variant="primary"
          icon={<IoArrowForwardOutline />}
          className={classes.memberCardInitButton}
          onClick={onAddMember}
          disabled={isDisabled}
        >
          {!member.isCompleted ? "Completar información" : "Editar"}
        </LightButton>
      )}
      {!member && (
        <LightButton
          size="lg"
          variant="primary"
          icon={<IoAddOutline />}
          className={classes.memberCardInitButton}
          onClick={onAddMember}
          disabled={isDisabled}
        >
          Agregar persona
        </LightButton>
      )}
    </MemberCardBase>
  );
};

export default MemberCardInit;
