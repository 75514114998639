import { object, mixed } from "yup";

import { DocumentFileTypeEnum } from "apps/lead/context/evaluatorContext/EvaluatorTypes";

export const requiredText = "Obligatorio";

export const dependentFilesSchema = object().shape({
  [DocumentFileTypeEnum.EMPLOYMENT_CERTIFICATE]: mixed()
    .nullable()
    .test("fileLength", requiredText, (value) => value.length > 0),
});
