import { DocumentFileTypeEnum as ContextDocumentFileTypeEnum } from "apps/lead/context/evaluatorContext/EvaluatorTypes";
import { DocumentFileTypeEnum } from "apps/lead/models/Evaluation";

export const FileTypeMapping = {
  [DocumentFileTypeEnum.FRONT_CI]: ContextDocumentFileTypeEnum.FRONT_CI,
  [DocumentFileTypeEnum.BACK_CI]: ContextDocumentFileTypeEnum.BACK_CI,
  [DocumentFileTypeEnum.SELFIE_CI]: ContextDocumentFileTypeEnum.SELFIE_CI,
  [DocumentFileTypeEnum.BANK_STATEMENT]:
    ContextDocumentFileTypeEnum.BANK_STATEMENT,
  [DocumentFileTypeEnum.PENSION_SALARY_SETTLEMENT]:
    ContextDocumentFileTypeEnum.PENSION_SALARY_SETTLEMENT,
  [DocumentFileTypeEnum.PENSIONER_CERTIFICATE]:
    ContextDocumentFileTypeEnum.PENSIONER_CERTIFICATE,
  [DocumentFileTypeEnum.EMPLOYMENT_CERTIFICATE]:
    ContextDocumentFileTypeEnum.EMPLOYMENT_CERTIFICATE,
  [DocumentFileTypeEnum.SALARY_SETTLEMENT]:
    ContextDocumentFileTypeEnum.SALARY_SETTLEMENT,
  [DocumentFileTypeEnum.COMPANY_CONSTITUTION]:
    ContextDocumentFileTypeEnum.COMPANY_CONSTITUTION,
  [DocumentFileTypeEnum.COMPANY_INCOME_STATEMENT]:
    ContextDocumentFileTypeEnum.COMPANY_INCOME_STATEMENT,
  [DocumentFileTypeEnum.TAX_DECLARATION]:
    ContextDocumentFileTypeEnum.TAX_DECLARATION,
  [DocumentFileTypeEnum.UNKNOWN]: ContextDocumentFileTypeEnum.UNKNOWN,
  [DocumentFileTypeEnum.OTHER]: ContextDocumentFileTypeEnum.OTHER,
};
