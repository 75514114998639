import { DocumentFileTypeEnum } from "apps/lead/context/evaluatorContext/EvaluatorTypes";
import { IncomeSourceTypeEnum } from "apps/lead/models/Evaluation";

const DOCS_REQUIRED = {
  [DocumentFileTypeEnum.FRONT_CI]: {
    label: "Foto de tu identificación (Frente)",
    workerType: [
      IncomeSourceTypeEnum.DEPENDENT,
      IncomeSourceTypeEnum.INDEPENDENT,
      IncomeSourceTypeEnum.RETIRED,
      IncomeSourceTypeEnum.EMPTY,
    ],
  },
  [DocumentFileTypeEnum.BACK_CI]: {
    label: "Foto de tu identificación (Detrás)",
    workerType: [
      IncomeSourceTypeEnum.DEPENDENT,
      IncomeSourceTypeEnum.INDEPENDENT,
      IncomeSourceTypeEnum.RETIRED,
      IncomeSourceTypeEnum.EMPTY,
    ],
  },
  [DocumentFileTypeEnum.SELFIE_CI]: {
    label: "Foto de tu cara y de tu identificación donde se vea tu foto",
    workerType: [
      IncomeSourceTypeEnum.DEPENDENT,
      IncomeSourceTypeEnum.INDEPENDENT,
      IncomeSourceTypeEnum.RETIRED,
      IncomeSourceTypeEnum.EMPTY,
    ],
  },
};

export const DOCS_REQUIRED_CO = {
  ...DOCS_REQUIRED,
  [DocumentFileTypeEnum.COMPANY_CONSTITUTION]: {
    label:
      "Acta constitutiva de la empresa (Documento de la cámara de comercio)",
    workerType: [IncomeSourceTypeEnum.INDEPENDENT],
  },
  [DocumentFileTypeEnum.TAX_DECLARATION]: {
    label: "Declaración de renta de la empresa",
    workerType: [IncomeSourceTypeEnum.INDEPENDENT],
  },
  [DocumentFileTypeEnum.COMPANY_INCOME_STATEMENT]: {
    label: "Declaración IVA",
    workerType: [IncomeSourceTypeEnum.INDEPENDENT],
  },
  [DocumentFileTypeEnum.EMPLOYMENT_CERTIFICATE]: {
    label:
      "Contrato o certificado laboral, en donde se especifique el tipo de contrato",
    workerType: [IncomeSourceTypeEnum.DEPENDENT],
  },
  [DocumentFileTypeEnum.PENSIONER_CERTIFICATE]: {
    label: "Certificado de pensión",
    workerType: [IncomeSourceTypeEnum.RETIRED],
  },
  [DocumentFileTypeEnum.BANK_STATEMENT]: {
    label: "Últimos 3 extractos bancarios",
    workerType: [
      IncomeSourceTypeEnum.DEPENDENT,
      IncomeSourceTypeEnum.INDEPENDENT,
      IncomeSourceTypeEnum.RETIRED,
    ],
  },
  [DocumentFileTypeEnum.SALARY_SETTLEMENT]: {
    label: "Últimas 3 liquidaciones de sueldo",
    workerType: [IncomeSourceTypeEnum.DEPENDENT],
  },
  [DocumentFileTypeEnum.PENSION_SALARY_SETTLEMENT]: {
    label: "Últimas 3 liquidaciones de pensión",
    workerType: [IncomeSourceTypeEnum.RETIRED],
  },
};
