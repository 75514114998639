import { useParams } from "react-router-dom";
import { useEffect } from "react";
import useEvaluation from "apps/lead/pages/EvaluatorForm/hooks/useEvaluation";
import { Locale } from "models/Countries";
import useSetEvaluationData from "apps/lead/pages/EvaluatorForm/hooks/useSetEvaluationData";

const useEvaluationForm = () => {
  const { setEvaluationData } = useSetEvaluationData();

  const { evaluationId, country } = useParams<{
    evaluationId: string;
    country: Locale;
  }>();
  const {
    data: evaluation,
    isLoading,
    isSuccess,
    isError,
    refetch,
  } = useEvaluation({
    evaluationId,
    country,
  });

  useEffect(() => {
    if (isSuccess) setEvaluationData(evaluation);
  }, [isSuccess]);

  return {
    isLoading,
    isSuccess,
    isError,
    refetch,
  };
};

export default useEvaluationForm;
