import { Evaluation, EvaluationStatusEnum } from "apps/lead/models/Evaluation";
import { useEvaluation as useEvaluationContext } from "apps/lead/context/evaluatorContext/EvaluatorContext";
import {
  setApplicants,
  setEvaluation,
  setPage,
} from "apps/lead/context/evaluatorContext/EvaluatorActions";
import { EvaluatorPages } from "apps/lead/context/evaluatorContext/EvaluatorTypes";
import { parseUsersApplicant } from "apps/lead/pages/EvaluatorForm/utils/parsers";

const useSetEvaluationData = () => {
  const { dispatch } = useEvaluationContext();

  const setEvaluationData = async (evaluation: Evaluation) => {
    dispatch(setEvaluation(evaluation));
    if (evaluation.status === EvaluationStatusEnum.INCOMPLETE) {
      const parsedApplicants = await parseUsersApplicant(evaluation);
      dispatch(setApplicants(parsedApplicants));
    }
    if (evaluation.status !== EvaluationStatusEnum.INCOMPLETE) {
      const applicantsIncomplete = evaluation.members.filter(
        (member) => !member.isFormCompleted
      );
      const evaluationFiltered = {
        ...evaluation,
        members: applicantsIncomplete,
      };
      const parsedApplicants = await parseUsersApplicant(evaluationFiltered);
      dispatch(setApplicants(parsedApplicants));
      dispatch(setPage(EvaluatorPages.RESUME));
    }
  };

  return {
    setEvaluationData,
  };
};

export default useSetEvaluationData;
