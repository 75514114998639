import { useEvaluation } from "apps/lead/context/evaluatorContext/EvaluatorContext";
import { MemberRoleEnum } from "apps/lead/models/Evaluation";
import { MAX_EVALUATION_MEMBERS } from "apps/lead/pages/EvaluatorForm/components/EvaluationSummary/utils/constants";

const useInitEvaluation = () => {
  const {
    state: { applicants },
  } = useEvaluation();

  const isEveryoneCompleted = applicants.every(
    (applicant) => applicant.isCompleted
  );
  const mainMemberForm = applicants.find(
    (applicant) => applicant.extraInformation?.role === MemberRoleEnum.MAIN
  );

  const extraMembersToRender = MAX_EVALUATION_MEMBERS - applicants.length;
  const showInformation = !isEveryoneCompleted && extraMembersToRender > 0;

  return {
    mainMemberForm,
    extraMembersToRender,
    showInformation,
  };
};

export default useInitEvaluation;
