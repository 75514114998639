import {
  setActiveStep,
  setPage,
  setUserInformation,
} from "apps/lead/context/evaluatorContext/EvaluatorActions";
import { useEvaluation as useEvaluationContext } from "apps/lead/context/evaluatorContext/EvaluatorContext";
import { EvaluatorPages } from "apps/lead/context/evaluatorContext/EvaluatorTypes";
import useSetEvaluationData from "apps/lead/pages/EvaluatorForm/hooks/useSetEvaluationData";
import { Evaluation } from "apps/lead/models/Evaluation";

const useFinishMemberEvaluation = () => {
  const { setEvaluationData } = useSetEvaluationData();
  const { dispatch } = useEvaluationContext();

  const onResetForm = (data: Evaluation) => {
    setEvaluationData(data);
    dispatch(setPage(EvaluatorPages.RESUME));
    dispatch(setActiveStep(0));
    dispatch(setUserInformation(undefined));
  };

  return {
    onResetForm,
  };
};

export default useFinishMemberEvaluation;
