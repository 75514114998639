import { object } from "yup";
import { checkFilesLength } from "apps/lead/pages/EvaluatorForm/components/IncomeValidationFiles/utils/validationSchema";
import { DocumentFileTypeEnum } from "apps/lead/context/evaluatorContext/EvaluatorTypes";

export const requiredText = "Obligatorio";

export const retiredFilesSchema = object().shape({
  [DocumentFileTypeEnum.PENSION_SALARY_SETTLEMENT]: checkFilesLength(),
  [DocumentFileTypeEnum.BANK_STATEMENT]: checkFilesLength(),
});
