import { useEffect } from "react";

import { setUserInformation } from "apps/lead/context/evaluatorContext/EvaluatorActions";
import { useEvaluation } from "apps/lead/context/evaluatorContext/EvaluatorContext";
import { DocumentFileTypeEnum } from "apps/lead/models/Evaluation";
import useDocumentToFileList from "apps/lead/pages/EvaluatorForm/hooks/useDocumentToFileList";
import { parserApplicantDocuments } from "./utils/parsers";

const useSetApplicantFiles = () => {
  const {
    state: { evaluation, user },
    dispatch,
  } = useEvaluation();
  const member = evaluation.members.find(
    (member) => member.memberId === user.memberId
  );
  const memberDocuments = member?.documents ?? [];
  const { data, isLoading, isSuccess } = useDocumentToFileList({
    documents: memberDocuments,
    documentTypeList: [
      DocumentFileTypeEnum.FRONT_CI,
      DocumentFileTypeEnum.BACK_CI,
      DocumentFileTypeEnum.SELFIE_CI,
      DocumentFileTypeEnum.EMPLOYMENT_CERTIFICATE,
      DocumentFileTypeEnum.COMPANY_INCOME_STATEMENT,
      DocumentFileTypeEnum.TAX_DECLARATION,
      DocumentFileTypeEnum.COMPANY_CONSTITUTION,
      DocumentFileTypeEnum.PENSIONER_CERTIFICATE,
      DocumentFileTypeEnum.PENSION_SALARY_SETTLEMENT,
      DocumentFileTypeEnum.SALARY_SETTLEMENT,
      DocumentFileTypeEnum.BANK_STATEMENT,
    ],
    memberId: user.memberId,
    arrayLength: 3,
  });

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        setUserInformation({
          ...user,
          ...parserApplicantDocuments(data),
        })
      );
    }
  }, [isSuccess]);

  return {
    isLoading,
  };
};

export default useSetApplicantFiles;
