import axios, { AxiosResponse } from "axios";
import qs from "query-string";

import { getToken, createHttp, createNoAuthHttp } from "services/helper";
import { CountryUpperCase } from "models/Countries";
import { UserResponse } from "models/User";
import { BACKEND_URL } from "env";

const qsConfig = { skipEmptyString: true, skipNull: true };

export default {
  getUser(token) {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token || getToken()}`,
    };
    const http = axios.create({ baseURL: BACKEND_URL, headers });

    return http.get("/propietary/v2/me/");
  },

  me() {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      return user;
    }
    return null;
  },

  getUserIdV2(data: {
    document?: string;
    document_type?: string;
    mail?: string;
  }): Promise<AxiosResponse<{ user_id: number }>> {
    return createNoAuthHttp(BACKEND_URL).get(
      `/public/get_user_id_by_document_or_mail?${qs.stringify(data, qsConfig)}`
    );
  },

  async getUserId(
    document?: string,
    docType?: string,
    email?: string
  ): Promise<AxiosResponse<{ user_id: number }>> {
    const params = {};
    if (document) {
      params["document"] = document;
      params["document_type"] = docType;
    }
    if (email) {
      params["mail"] = email;
    }
    return createNoAuthHttp(BACKEND_URL).get(
      "/public/get_user_id_by_document_or_mail",
      {
        params,
      }
    );
  },

  getV2(token: string): Promise<AxiosResponse<UserResponse>> {
    return createHttp(token).get("/admin/v2/users/my_info/");
  },

  async setUserCountry(
    token: string,
    country: CountryUpperCase
  ): Promise<AxiosResponse<Record<string, unknown>>> {
    return createHttp(token, BACKEND_URL).patch("/v2/users/set-country", {
      country,
    });
  },
};
