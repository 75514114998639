import { Grid, Typography } from "@material-ui/core";

import useSubscriptionPlansHeaderStyles from "./SubscriptionPlansHeaderStyles";

interface Props {
  hasPlan: boolean;
}

const SubscriptionPlansHeader = ({ hasPlan }: Props) => {
  const classes = useSubscriptionPlansHeaderStyles();

  return (
    <Grid container item xs={12}>
      <Grid container item>
        <Grid item alignContent="flex-start">
          <Typography className={classes.title}>
            {!hasPlan ? "Elige tu plan" : "Plan suscrito"}
          </Typography>
          <Typography className={classes.subtitle}>
            {!hasPlan
              ? "Revisa los planes que tenemos disponibles y elige el más conveniente para tí"
              : "Este es el plan al cual te encuentras suscrito"}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SubscriptionPlansHeader;
