import { IncomeSourceTypeEnum } from "apps/lead/models/Evaluation";

export const workerTypeOptions = [
  {
    label: "Dependiente",
    value: IncomeSourceTypeEnum.DEPENDENT,
  },
  {
    label: "Independiente",
    value: IncomeSourceTypeEnum.INDEPENDENT,
  },
  {
    label: "Pensionado",
    value: IncomeSourceTypeEnum.RETIRED,
  },
];
