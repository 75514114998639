import { object, mixed } from "yup";

import { DocumentFileTypeEnum } from "apps/lead/context/evaluatorContext/EvaluatorTypes";

export const requiredText = "Obligatorio";

export const independentFilesSchema = object().shape({
  [DocumentFileTypeEnum.TAX_DECLARATION]: mixed()
    .nullable()
    .test("fileLength", requiredText, (value) => value.length > 0),
  [DocumentFileTypeEnum.COMPANY_INCOME_STATEMENT]: mixed()
    .nullable()
    .test("fileLength", requiredText, (value) => value.length > 0),
  [DocumentFileTypeEnum.COMPANY_CONSTITUTION]: mixed()
    .nullable()
    .test("fileLength", requiredText, (value) => value.length > 0),
});
