import {
  DocumentFileTypeEnum,
  IdentityFiles,
} from "apps/lead/context/evaluatorContext/EvaluatorTypes";
import { IIdentificationForm } from "apps/lead/pages/EvaluatorForm/components/IdentificationFiles/utils/types";
import { DocumentFiles } from "apps/lead/pages/EvaluatorForm/components/EmploymentValidationForm/utils/types";
import { DocumentFileTypeEnum as DocumentFileTypeServiceEnum } from "apps/lead/models/Evaluation";

export const parser = (data: IIdentificationForm) => ({
  ...(data[DocumentFileTypeEnum.BACK_CI] && {
    [DocumentFileTypeServiceEnum.BACK_CI]: [data[DocumentFileTypeEnum.BACK_CI]],
  }),
  ...(data[DocumentFileTypeEnum.FRONT_CI] && {
    [DocumentFileTypeServiceEnum.FRONT_CI]: [
      data[DocumentFileTypeEnum.FRONT_CI],
    ],
  }),
  ...(data[DocumentFileTypeEnum.SELFIE_CI] && {
    [DocumentFileTypeServiceEnum.SELFIE_CI]: [
      data[DocumentFileTypeEnum.SELFIE_CI],
    ],
  }),
});

export const parserMutationFiles = (
  data: DocumentFiles<IIdentificationForm>
) => ({
  ...(data[DocumentFileTypeEnum.FRONT_CI] && {
    [DocumentFileTypeEnum.FRONT_CI]: [data[DocumentFileTypeEnum.FRONT_CI]],
  }),
  ...(data[DocumentFileTypeEnum.BACK_CI] && {
    [DocumentFileTypeEnum.BACK_CI]: [data[DocumentFileTypeEnum.BACK_CI]],
  }),
  ...(data[DocumentFileTypeEnum.SELFIE_CI] && {
    [DocumentFileTypeEnum.SELFIE_CI]: [data[DocumentFileTypeEnum.SELFIE_CI]],
  }),
});

export const parserDefaultValues = (files: IdentityFiles) => ({
  [DocumentFileTypeEnum.FRONT_CI]: files?.[DocumentFileTypeEnum.FRONT_CI]?.[0],
  [DocumentFileTypeEnum.BACK_CI]: files?.[DocumentFileTypeEnum.BACK_CI]?.[0],
  [DocumentFileTypeEnum.SELFIE_CI]:
    files?.[DocumentFileTypeEnum.SELFIE_CI]?.[0],
});
