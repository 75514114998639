import { useQuery, UseQueryResult } from "react-query";
import { AxiosError } from "axios";

import documentTypeService from "services/documentTypeService";
import { localeToCountry } from "utils/countryCode";
import { DocumentType } from "models/Document";
import { Locale } from "models/Countries";

import { DocumentTypes } from "./utils/types";

const useDocumentTypeListPublic = (country: Locale) => {
  const {
    isLoading,
    error,
    data = [],
    isError,
    isSuccess,
  }: UseQueryResult<DocumentTypes[], AxiosError> = useQuery<
    DocumentTypes[],
    AxiosError
  >(
    ["document_type_list_public", country],
    async () => {
      const response = await documentTypeService.getDocumentList(
        localeToCountry[country]
      );
      const docTypes: DocumentType[] = response.data.results;
      const parseDocumentsType = docTypes.map((doc) => ({
        value: doc.id,
        label: doc.type,
        identifier: doc.identifier,
        regex: doc.validation_regex,
      }));
      return parseDocumentsType;
    },
    {
      enabled: !!country,
    }
  );

  return {
    isLoading,
    error,
    data,
    isError,
    isSuccess,
  };
};

export default useDocumentTypeListPublic;
