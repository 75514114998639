import { useMemo } from "react";

import EmploymentValidationForm from "apps/lead/pages/EvaluatorForm/components/EmploymentValidationForm";
import IncomeValidationFiles from "apps/lead/pages/EvaluatorForm/components/IncomeValidationFiles";
import IdentificationFiles from "apps/lead/pages/EvaluatorForm/components/IdentificationFiles";
import WorkerTypeForm from "apps/lead/pages/EvaluatorForm/components/WorkerTypeForm";
import ApplicantForm from "apps/lead/pages/EvaluatorForm/components/ApplicantForm";
import EmployerForm from "apps/lead/pages/EvaluatorForm/components/EmployerForm";
import AddressForm from "apps/lead/pages/EvaluatorForm/components/AddressForm";
import { useEvaluation } from "apps/lead/context/evaluatorContext/EvaluatorContext";
import {
  IncomeSourceType,
  IncomeSourceTypeEnum,
} from "apps/lead/models/Evaluation";
import { Locale, LocaleCountries } from "models/Countries";

const APPLICANT_INFORMATION_STEPS = {
  INCOME_VALIDATION: IncomeValidationFiles,
  EMPLOYMENT_VALIDATION: EmploymentValidationForm,
  EMPLOYMENT: EmployerForm,
  WORKER_TYPE: WorkerTypeForm,
  ADDRESS: AddressForm,
  USER_FORM: ApplicantForm,
  IDENTIFICATION: IdentificationFiles,
};

const getEvaluationFormStepsColombia = (workerType: IncomeSourceType) => [
  APPLICANT_INFORMATION_STEPS.USER_FORM,
  APPLICANT_INFORMATION_STEPS.IDENTIFICATION,
  APPLICANT_INFORMATION_STEPS.ADDRESS,
  APPLICANT_INFORMATION_STEPS.WORKER_TYPE,
  ...(workerType && workerType !== IncomeSourceTypeEnum.RETIRED
    ? [APPLICANT_INFORMATION_STEPS.EMPLOYMENT]
    : []),
  APPLICANT_INFORMATION_STEPS.EMPLOYMENT_VALIDATION,
  APPLICANT_INFORMATION_STEPS.INCOME_VALIDATION,
];

const useEvaluationFormSteps = () => {
  const { state } = useEvaluation();
  const getStepsByCountry = (country: Locale, workerType: IncomeSourceType) => {
    if (country === LocaleCountries.CL) return [];
    if (country === LocaleCountries.MX) return [];
    if (country === LocaleCountries.CO)
      return getEvaluationFormStepsColombia(workerType);
  };

  const formSteps = useMemo(
    () => getStepsByCountry(state.country, state.user?.employment?.workerType),
    [state.country, state.user?.employment?.workerType]
  );

  return formSteps;
};

export default useEvaluationFormSteps;
