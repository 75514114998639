import InformationBase from "apps/lead/pages/EvaluatorForm/components/EvaluationSummary/components/InformationBase";
import MemberCardSummary from "apps/lead/pages/EvaluatorForm/components/EvaluationSummary/components/MemberCardSummary";
import { classes } from "apps/lead/pages/EvaluatorForm/components/EvaluationSummary/components/Summary/SummaryStyles";
import ExtraMembers from "apps/lead/pages/EvaluatorForm/components/EvaluationSummary/components/ExtraMembers";
import useSummary from "apps/lead/pages/EvaluatorForm/components/EvaluationSummary/hooks/useSummary";

const Summary = () => {
  const { membersCompleted, membersToRender, showIncompleteApplicantInfo } =
    useSummary();

  return (
    <>
      {showIncompleteApplicantInfo && (
        <InformationBase
          informationText="Es necesario completar la información del postulante incompleto
              poder agregar a otra persona."
        />
      )}
      <div className={classes.summaryCardsContainer}>
        {membersCompleted.map((member) => (
          <MemberCardSummary key={member.memberId} member={member} />
        ))}
        <ExtraMembers maxCardsToRender={membersToRender} />
      </div>
    </>
  );
};

export default Summary;
