import { FormProvider, SubmitHandler } from "react-hook-form";

import FieldInputFile from "@houm-com/ui/FieldInputFile";

import { independentFilesSchema } from "apps/lead/pages/EvaluatorForm/components/EmploymentValidationForm/components/co/IndependentForm/utils/validationSchema";
import { IIndependentFilesForm } from "apps/lead/pages/EvaluatorForm/components/EmploymentValidationForm/components/co/IndependentForm/utils/types";
import { classes } from "apps/lead/pages/EvaluatorForm/components/EmploymentValidationForm/components/co/IndependentForm/IndependentFormStyles";
import { useEvaluation } from "apps/lead/context/evaluatorContext/EvaluatorContext";
import CallToAction from "apps/lead/components/EvaluatorForm/CallToAction";
import FormWrapper from "apps/lead/components/EvaluatorForm/FormWrapper";
import useReactHookForm from "hooks/useReactHookForm";
import {
  parserDefaultData,
  parserMutationFiles,
} from "apps/lead/pages/EvaluatorForm/components/EmploymentValidationForm/components/co/IndependentForm/utils/parsers";
import { DocumentFileTypeEnum } from "apps/lead/context/evaluatorContext/EvaluatorTypes";
import { ALLOWED_MIME_TYPES } from "apps/lead/pages/EvaluatorForm/utils/constants";

interface Props {
  onUploadFiles: (
    data: Record<keyof IIndependentFilesForm, FileList[]>
  ) => void;
  isLoading: boolean;
}

const IndependentForm = ({ onUploadFiles, isLoading }: Props) => {
  const {
    state: { user: userInformation },
  } = useEvaluation();
  const defaultValues = parserDefaultData(userInformation.employmentFiles);
  const { methods, errors } = useReactHookForm<IIndependentFilesForm>({
    formSchema: independentFilesSchema,
    formOptions: {
      defaultValues,
    },
  });
  const onSubmit: SubmitHandler<IIndependentFilesForm> = async (data) => {
    if (isLoading) return;
    const transformedData = parserMutationFiles(data);
    onUploadFiles(transformedData);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormWrapper classNameFormWrapper={classes.independentFormRoot}>
          <FieldInputFile
            id={DocumentFileTypeEnum.COMPANY_CONSTITUTION}
            label="Acta constitutiva de  la empresa (Documento de la cámara de comercio)"
            subLabel="Debe ser empresa vigente y más de 6 meses de antigüedad"
            allowedMimeTypes={ALLOWED_MIME_TYPES}
            classNameInputButton={classes.independentFormInputFile}
            splitLength={40}
            required
            form="novalidateform"
            file={defaultValues[DocumentFileTypeEnum.COMPANY_CONSTITUTION]}
            errorMessage={
              errors[DocumentFileTypeEnum.COMPANY_CONSTITUTION]?.message
            }
            {...methods.register(DocumentFileTypeEnum.COMPANY_CONSTITUTION)}
          />
          <FieldInputFile
            id={DocumentFileTypeEnum.COMPANY_INCOME_STATEMENT}
            label="Declaración de renta de la empresa"
            subLabel="Los ingresos deben ser 3 veces mayor al monto del arriendo"
            allowedMimeTypes={ALLOWED_MIME_TYPES}
            classNameInputButton={classes.independentFormInputFile}
            splitLength={40}
            required
            form="novalidateform"
            file={defaultValues[DocumentFileTypeEnum.COMPANY_INCOME_STATEMENT]}
            errorMessage={
              errors[DocumentFileTypeEnum.COMPANY_INCOME_STATEMENT]?.message
            }
            {...methods.register(DocumentFileTypeEnum.COMPANY_INCOME_STATEMENT)}
          />
          <FieldInputFile
            id={DocumentFileTypeEnum.TAX_DECLARATION}
            label="Declaración IVA"
            subLabel="Los últimos 3 meses deben validar que los ingresos declarados sean reales."
            allowedMimeTypes={ALLOWED_MIME_TYPES}
            classNameInputButton={classes.independentFormInputFile}
            splitLength={40}
            required
            form="novalidateform"
            file={defaultValues[DocumentFileTypeEnum.TAX_DECLARATION]}
            errorMessage={errors[DocumentFileTypeEnum.TAX_DECLARATION]?.message}
            {...methods.register(DocumentFileTypeEnum.TAX_DECLARATION)}
          />
        </FormWrapper>
        <CallToAction label="Continuar" isLoading={isLoading} />
      </form>
    </FormProvider>
  );
};

export default IndependentForm;
