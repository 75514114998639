import { useEffect } from "react";
import { useMutation } from "react-query";

import { alert } from "@houm-com/ui/Alert";

import { MemberPersonalInformationForm } from "apps/lead/pages/EvaluatorForm/hooks/useUpdateMemberInformation/utils/types";
import { parserPersonInformation } from "apps/lead/pages/EvaluatorForm/hooks/useUpdateMemberInformation/utils/parsers";
import { MemberPersonalInformationSchema } from "apps/lead/models/Evaluation";
import evaluationService from "apps/lead/services/EvaluationService";
import { Locale } from "models/Countries";

interface Props {
  memberId: string;
  country: Locale;
}

const useUpdateMemberInformation = ({ memberId, country }: Props) => {
  const queryMutate = useMutation(
    async (data: MemberPersonalInformationForm) => {
      const parsedData = parserPersonInformation(data);
      const { data: memberPersonInformation } =
        await evaluationService.updatePersonInformation(
          memberId,
          country,
          parsedData
        );
      return MemberPersonalInformationSchema.parse(memberPersonInformation);
    }
  );

  useEffect(() => {
    if (queryMutate.isError)
      alert.error({
        message: "¡Ocurrió un error!",
        disclaimer: "Por favor, intenta de nuevo más tarde",
      });
  }, [queryMutate.isError]);

  return queryMutate;
};

export default useUpdateMemberInformation;
