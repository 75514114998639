export const classes = {
  memberCardBaseRoot:
    "flex flex-col gap-4 self-stretch bg-[#E8ECEE]/50 items-center max-w-full md:max-w-[300px] w-full h-[400px] p-6 rounded-3xl",
  memberCardBaseHeader: "flex flex-col gap-1 items-center justify-center",
  memberCardBaseDisabled: "bg-[#E8ECEE]/25 text-black-60",
  memberCardBaseHover: "hover:bg-[#001631] hover:text-white group",
  memberCardBaseTitle: "text-h24 font-['Inter'] text-center",
  memberCardBaseStatusTagContainer:
    "!min-h-[20px] !h-5 w-full flex justify-end",
  memberCardBaseRequiredLabel: "text-h12",
};
