import {
  DocumentFileTypeEnum,
  EmploymentFiles,
} from "apps/lead/context/evaluatorContext/EvaluatorTypes";
import { IRetiredFilesForm } from "apps/lead/pages/EvaluatorForm/components/EmploymentValidationForm/components/co/RetiredForm/utils/types";
import { DocumentFiles } from "apps/lead/pages/EvaluatorForm/components/EmploymentValidationForm/utils/types";

export const parserDefaultData = (files: EmploymentFiles) => ({
  [DocumentFileTypeEnum.PENSIONER_CERTIFICATE]:
    files?.[DocumentFileTypeEnum.PENSIONER_CERTIFICATE]?.[0],
});

export const parserMutationFiles = (
  data: DocumentFiles<IRetiredFilesForm>
) => ({
  ...(data[DocumentFileTypeEnum.PENSIONER_CERTIFICATE] && {
    [DocumentFileTypeEnum.PENSIONER_CERTIFICATE]: [
      data[DocumentFileTypeEnum.PENSIONER_CERTIFICATE],
    ],
  }),
});
