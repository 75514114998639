import { AxiosResponse } from "axios";

import { createNoAuthHttp } from "services/helper";
import { Locale } from "models/Countries";
import { EVALUATIONS_URL } from "env";

import {
  CreateMemberRequest,
  EvaluationResponse,
  Member,
  MemberDetails,
  MemberRequest,
} from "./types";

export default {
  getEvaluation: (
    evaluationId: string,
    country: Locale
  ): Promise<AxiosResponse<EvaluationResponse>> =>
    createNoAuthHttp(EVALUATIONS_URL).get(
      `/${country}/v3/rentals/tenant/evaluations/${evaluationId}`
    ),
  createMember: (
    evaluationId: string,
    country: Locale,
    data: CreateMemberRequest
  ): Promise<AxiosResponse<{ id: string }>> =>
    createNoAuthHttp(EVALUATIONS_URL).post(
      `/${country}/v3/rentals/tenant/evaluations/${evaluationId}/members`,
      { ...data }
    ),
  updateMember: (
    memberId: string,
    country: Locale,
    data: MemberRequest
  ): Promise<AxiosResponse<Member>> =>
    createNoAuthHttp(EVALUATIONS_URL).put(
      `/${country}/v3/rentals/tenant/evaluation_members/${memberId}`,
      { ...data }
    ),
  uploadFiles: (memberId: string, country: Locale, data: FormData) =>
    createNoAuthHttp(EVALUATIONS_URL, "multipart/form-data").post(
      `/${country}/v3/rentals/tenant/evaluation_members/${memberId}/documents`,
      data
    ),
  updatePersonInformation: (
    memberId: string,
    country: Locale,
    data: Partial<MemberDetails>
  ): Promise<AxiosResponse<MemberDetails>> =>
    createNoAuthHttp(EVALUATIONS_URL).put(
      `/${country}/v3/rentals/tenant/evaluation_members/${memberId}/person_information`,
      data
    ),
  startEvaluationProcess: (evaluationId: string, country: Locale) =>
    createNoAuthHttp(EVALUATIONS_URL).patch(
      `/${country}/v3/rentals/tenant/evaluations/${evaluationId}`,
      {
        status: "pending",
      }
    ),
};
