import { useEffect, useMemo } from "react";

import Spinner from "@houm-com/ui/Spinner";

import { useEvaluation } from "apps/lead/context/evaluatorContext/EvaluatorContext";
import { setSteps } from "apps/lead/context/evaluatorContext/EvaluatorActions";
import useSetIdentificationFiles from "apps/lead/pages/EvaluatorForm/components/UserInformationForm/hooks/useSetApplicantFiles";
import useEvaluationFormSteps from "apps/lead/pages/EvaluatorForm/hooks/useEvaluationFormSteps";
import { classes } from "apps/lead/pages/EvaluatorForm/components/UserInformationForm/UserInformationFormStyles";

const UserInformationForm = () => {
  const { isLoading } = useSetIdentificationFiles();
  const formSteps = useEvaluationFormSteps();

  const { state, dispatch } = useEvaluation();
  const { activeStep } = state;

  useEffect(() => {
    dispatch(setSteps(formSteps.length));
  }, [formSteps]);

  const CurrentStep = useMemo(() => formSteps[activeStep], [activeStep]);

  if (isLoading)
    return (
      <div className={classes.userInformationFormSpinner}>
        <Spinner variant="primary" size="2xl" />
      </div>
    );

  return <CurrentStep />;
};

export default UserInformationForm;
