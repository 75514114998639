import {
  DocumentFileTypeEnum,
  EmploymentFiles,
} from "apps/lead/context/evaluatorContext/EvaluatorTypes";
import { IIndependentFilesForm } from "apps/lead/pages/EvaluatorForm/components/EmploymentValidationForm/components/co/IndependentForm/utils/types";
import { DocumentFiles } from "apps/lead/pages/EvaluatorForm/components/EmploymentValidationForm/utils/types";

export const parserDefaultData = (files: EmploymentFiles) => ({
  [DocumentFileTypeEnum.COMPANY_CONSTITUTION]:
    files?.[DocumentFileTypeEnum.COMPANY_CONSTITUTION]?.[0],
  [DocumentFileTypeEnum.COMPANY_INCOME_STATEMENT]:
    files?.[DocumentFileTypeEnum.COMPANY_INCOME_STATEMENT]?.[0],
  [DocumentFileTypeEnum.TAX_DECLARATION]:
    files?.[DocumentFileTypeEnum.TAX_DECLARATION]?.[0],
});

export const parserMutationFiles = (
  data: DocumentFiles<IIndependentFilesForm>
) => ({
  ...(data[DocumentFileTypeEnum.COMPANY_CONSTITUTION] && {
    [DocumentFileTypeEnum.COMPANY_CONSTITUTION]: [
      data[DocumentFileTypeEnum.COMPANY_CONSTITUTION],
    ],
  }),
  ...(data[DocumentFileTypeEnum.COMPANY_INCOME_STATEMENT] && {
    [DocumentFileTypeEnum.COMPANY_INCOME_STATEMENT]: [
      data[DocumentFileTypeEnum.COMPANY_INCOME_STATEMENT],
    ],
  }),
  ...(data[DocumentFileTypeEnum.TAX_DECLARATION] && {
    [DocumentFileTypeEnum.TAX_DECLARATION]: [
      data[DocumentFileTypeEnum.TAX_DECLARATION],
    ],
  }),
});
