import { UserApplicant } from "apps/lead/context/evaluatorContext/EvaluatorTypes";
import {
  IncomeSourceType,
  IncomeSourceTypeEnum,
  MemberPersonalInformation,
} from "apps/lead/models/Evaluation";

export const parseDefaultData = (userInformation: Partial<UserApplicant>) => ({
  workerType: userInformation.employment?.workerType,
});

export const parseEmploymentData = (workerType: IncomeSourceType) => ({
  ...(workerType === IncomeSourceTypeEnum.RETIRED && {
    employer: "-",
    occupation: "-",
  }),
});

export const parseWorkerTypeData = (
  userInformation: Partial<UserApplicant>,
  data: MemberPersonalInformation
) => ({
  ...userInformation,
  employment: {
    ...userInformation.employment,
    workerType: data.incomeSourceType,
    ...parseEmploymentData(data.incomeSourceType),
  },
});
