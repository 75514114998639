import { DocumentFileTypeEnum } from "apps/lead/models/Evaluation";
import { DocumentFileTypeEnum as ContextDocumentFileTypeEnum } from "apps/lead/context/evaluatorContext/EvaluatorTypes";

const keyMapping = {
  [ContextDocumentFileTypeEnum.FRONT_CI]: DocumentFileTypeEnum.FRONT_CI,
  [ContextDocumentFileTypeEnum.BACK_CI]: DocumentFileTypeEnum.BACK_CI,
  [ContextDocumentFileTypeEnum.SELFIE_CI]: DocumentFileTypeEnum.SELFIE_CI,
  [ContextDocumentFileTypeEnum.BANK_STATEMENT]:
    DocumentFileTypeEnum.BANK_STATEMENT,
  [ContextDocumentFileTypeEnum.PENSION_SALARY_SETTLEMENT]:
    DocumentFileTypeEnum.PENSION_SALARY_SETTLEMENT,
  [ContextDocumentFileTypeEnum.PENSIONER_CERTIFICATE]:
    DocumentFileTypeEnum.PENSIONER_CERTIFICATE,
  [ContextDocumentFileTypeEnum.EMPLOYMENT_CERTIFICATE]:
    DocumentFileTypeEnum.EMPLOYMENT_CERTIFICATE,
  [ContextDocumentFileTypeEnum.SALARY_SETTLEMENT]:
    DocumentFileTypeEnum.SALARY_SETTLEMENT,
  [ContextDocumentFileTypeEnum.COMPANY_CONSTITUTION]:
    DocumentFileTypeEnum.COMPANY_CONSTITUTION,
  [ContextDocumentFileTypeEnum.COMPANY_INCOME_STATEMENT]:
    DocumentFileTypeEnum.COMPANY_INCOME_STATEMENT,
  [ContextDocumentFileTypeEnum.TAX_DECLARATION]:
    DocumentFileTypeEnum.TAX_DECLARATION,
  [ContextDocumentFileTypeEnum.UNKNOWN]: DocumentFileTypeEnum.UNKNOWN,
  [ContextDocumentFileTypeEnum.OTHER]: DocumentFileTypeEnum.OTHER,
};

export const parserFiles = (data: { [key: string]: FileList[] }) => {
  const formData = new FormData();
  Object.entries(data).map(([key, value]) =>
    value.forEach((fileList) => formData.append(keyMapping[key], fileList[0]))
  );
  return formData;
};
