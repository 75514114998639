import { useEvaluation } from "apps/lead/context/evaluatorContext/EvaluatorContext";
import { MemberRoleEnum } from "apps/lead/models/Evaluation";

const useEvaluationSummary = () => {
  const {
    state: { applicants },
  } = useEvaluation();

  const isEveryoneCompleted = applicants.every(
    (applicant) => applicant.isCompleted
  );
  const mainMemberForm = applicants.find(
    (applicant) => applicant.extraInformation?.role === MemberRoleEnum.MAIN
  );
  const isMainFormCompleted = !!mainMemberForm?.isCompleted;

  return {
    isMainFormCompleted,
    isEveryoneCompleted,
  };
};

export default useEvaluationSummary;
