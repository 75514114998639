import { useEffect } from "react";
import { FormProvider, SubmitHandler } from "react-hook-form";
import { isEqual } from "lodash";

import FieldRadioButton from "@houm-com/ui/FieldRadioButton";

import { workerTypeSchema } from "apps/lead/pages/EvaluatorForm/components/WorkerTypeForm/utils/validationSchema";
import { workerTypeOptions } from "apps/lead/pages/EvaluatorForm/components/WorkerTypeForm/utils/constants";
import { IWorkerTypeForm } from "apps/lead/pages/EvaluatorForm/components/WorkerTypeForm/utils/types";
import FormWrapper from "apps/lead/components/EvaluatorForm/FormWrapper/FormWrapper";
import CallToAction from "apps/lead/components/EvaluatorForm/CallToAction";
import Header from "apps/lead/components/EvaluatorForm/HeaderForm/Header";
import useReactHookForm from "hooks/useReactHookForm";
import { useEvaluation } from "apps/lead/context/evaluatorContext/EvaluatorContext";
import {
  setActiveStep,
  setUserInformation,
} from "apps/lead/context/evaluatorContext/EvaluatorActions";
import useUpdateMemberInformation from "apps/lead/pages/EvaluatorForm/hooks/useUpdateMemberInformation";
import {
  parseEmploymentData,
  parseWorkerTypeData,
} from "apps/lead/pages/EvaluatorForm/components/WorkerTypeForm/utils/parsers";

const WorkerTypeForm = () => {
  const {
    state: { user: userInformation, activeStep, country },
    dispatch,
  } = useEvaluation();
  const { isLoading, mutate, isSuccess, data } = useUpdateMemberInformation({
    memberId: userInformation?.memberId,
    country,
  });

  const { methods, errors } = useReactHookForm<IWorkerTypeForm>({
    formSchema: workerTypeSchema,
    formOptions: {
      defaultValues: {
        workerType: userInformation.employment?.workerType,
      },
    },
  });

  const onSubmit: SubmitHandler<IWorkerTypeForm> = (data) => {
    if (isLoading) return;
    if (isEqual(data.workerType, userInformation.employment?.workerType)) {
      dispatch(setActiveStep(activeStep + 1));
    } else {
      mutate({
        workerType: data.workerType,
        ...parseEmploymentData(data.workerType),
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(setUserInformation(parseWorkerTypeData(userInformation, data)));
      dispatch(setActiveStep(activeStep + 1));
    }
  }, [isSuccess]);

  return (
    <FormProvider {...methods}>
      <Header
        title="Cuéntanos un poco más de ti"
        stepTitle="Información Laboral"
      />
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormWrapper>
          <FieldRadioButton
            label="Situación laboral"
            id="workerType"
            options={workerTypeOptions}
            required
            form="novalidateform"
            errorMessage={errors.workerType?.message}
            {...methods.register("workerType")}
          />
        </FormWrapper>
        <CallToAction label="Continuar" isLoading={isLoading} />
      </form>
    </FormProvider>
  );
};

export default WorkerTypeForm;
