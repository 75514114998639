import { reduce } from "lodash";
import { useQuery } from "react-query";

import {
  DocumentFileType,
  EvaluationDocument,
} from "apps/lead/models/Evaluation";
import { FileTypeMapping } from "./utils/constants";
import { DocumentFileTypeEnum } from "apps/lead/context/evaluatorContext/EvaluatorTypes";

function filesToFileList(files: File[]): FileList {
  const dataTransfer = new DataTransfer();
  files.forEach((file) => dataTransfer.items.add(file));
  return dataTransfer.files;
}

const urlToFile = async (url: string, fileName: string, mimeType: string) => {
  const response = await fetch(url);
  const data = await response.blob();
  const file = new File([data], fileName, { type: mimeType });
  return file;
};

interface Props {
  documents: EvaluationDocument[];
  documentTypeList: DocumentFileType[];
  arrayLength?: number;
  memberId: string;
}

const useDocumentToFileList = ({
  documents,
  documentTypeList,
  arrayLength = 1,
  memberId,
}: Props) =>
  useQuery(
    ["documentsToFileList", documentTypeList, arrayLength, memberId],
    async () => {
      const documentsByType = documents.filter((doc) =>
        documentTypeList.includes(doc.documentType)
      );
      if (!documentsByType.length) return undefined;

      const fileObject = reduce(
        documentTypeList,
        (acc, type) => {
          const files = documentsByType
            .filter((doc) => doc.documentType === type)
            .sort(
              (a, b) =>
                new Date(b.createdAt).getTime() -
                new Date(a.createdAt).getTime()
            )
            .slice(0, arrayLength);
          acc[FileTypeMapping[type]] = files;
          return acc;
        },
        {}
      );
      // @ts-ignore
      const transformed: { [K in DocumentFileTypeEnum]: FileList[] } = {};
      await Promise.all(
        Object.keys(fileObject).map(async (key) => {
          const documentArray: EvaluationDocument[] = fileObject[key];
          transformed[key] = await Promise.all(
            documentArray.map(async (doc: EvaluationDocument) => {
              const file = await urlToFile(
                doc.url,
                doc.filename,
                doc.contentType
              );
              return filesToFileList([file]);
            })
          );
        })
      );
      return transformed;
    },
    {
      enabled: documents.length > 0,
      staleTime: 5 * 60 * 1000, // Cache for 5 minutes
      retryOnMount: true,
    }
  );

export default useDocumentToFileList;
