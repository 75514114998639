import { SnackbarProvider } from "notistack";
import { Route } from "react-router-dom";

import { EvaluatorProvider } from "apps/lead/context/evaluatorContext/EvaluatorContext";
import EvaluatorForm from "apps/lead/pages/EvaluatorForm";
import Landing from "apps/payments/pages/LandingPayment";

const TenantPublicRoutes = () => (
  <>
    <Route
      exact
      path={[
        "/tenant/:movementType/:countryCode/pay/:id",
        "/:movementType/:countryCode/pay/:id",
      ]}
    >
      <Landing />
    </Route>
    <SnackbarProvider>
      <Route exact path={["/lease/:country/evaluation-form/:evaluationId"]}>
        <EvaluatorProvider>
          <EvaluatorForm />
        </EvaluatorProvider>
      </Route>
    </SnackbarProvider>
  </>
);

export default TenantPublicRoutes;
