import { FormProvider, SubmitHandler } from "react-hook-form";

import FieldInputFile from "@houm-com/ui/FieldInputFile";

import { identificationFilesSchema } from "apps/lead/pages/EvaluatorForm/components/IdentificationFiles/utils/validationSchema";
import { classes } from "apps/lead/pages/EvaluatorForm/components/IdentificationFiles/IdentificationFilesStyles";
import { IIdentificationForm } from "apps/lead/pages/EvaluatorForm/components/IdentificationFiles/utils/types";
import FormWrapper from "apps/lead/components/EvaluatorForm/FormWrapper/FormWrapper";
import { useEvaluation } from "apps/lead/context/evaluatorContext/EvaluatorContext";
import CallToAction from "apps/lead/components/EvaluatorForm/CallToAction";
import Header from "apps/lead/components/EvaluatorForm/HeaderForm/Header";
import useReactHookForm from "hooks/useReactHookForm";
import {
  parserDefaultValues,
  parserMutationFiles,
} from "apps/lead/pages/EvaluatorForm/components/IdentificationFiles/utils/parsers";
import useDocumentFiles from "apps/lead/pages/EvaluatorForm/hooks/useDocumentFiles";
import {
  DocumentFileTypeEnum,
  DocumentObjectTypeEnum,
} from "apps/lead/context/evaluatorContext/EvaluatorTypes";
import { setActiveStep } from "apps/lead/context/evaluatorContext/EvaluatorActions";
import { ALLOWED_MIME_TYPES } from "apps/lead/pages/EvaluatorForm/utils/constants";

const IdentificationFiles = () => {
  const {
    state: { user: userInformation, activeStep },
    dispatch,
  } = useEvaluation();
  const defaultValues = parserDefaultValues(userInformation.identityFiles);
  const { uploadDocuments, isLoading } = useDocumentFiles<IIdentificationForm>({
    documentType: DocumentObjectTypeEnum.IDENTITY,
    onSuccess: () => dispatch(setActiveStep(activeStep + 1)),
  });
  const { methods, errors } = useReactHookForm<IIdentificationForm>({
    formSchema: identificationFilesSchema,
    formOptions: {
      defaultValues,
    },
  });
  const onSubmit: SubmitHandler<IIdentificationForm> = async (data) => {
    if (isLoading) return;
    const transformedData = parserMutationFiles(data);
    uploadDocuments(transformedData);
  };

  return (
    <FormProvider {...methods}>
      <Header
        title="Validación de identificación"
        subtitle="Estos documentos pueden estar en formato PDF o como imagen (JPEG, JPG o PNG)"
        stepTitle="Identificación"
      />
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormWrapper classNameFormWrapper={classes.identificationFilesWrapper}>
          <FieldInputFile
            id={DocumentFileTypeEnum.FRONT_CI}
            label="Foto de tu identificación (Frente)"
            allowedMimeTypes={ALLOWED_MIME_TYPES}
            classNameInputButton={classes.inputField}
            splitLength={40}
            required
            form="novalidateform"
            file={defaultValues?.[DocumentFileTypeEnum.FRONT_CI]}
            errorMessage={errors[DocumentFileTypeEnum.FRONT_CI]?.message}
            {...methods.register(DocumentFileTypeEnum.FRONT_CI)}
          />
          <FieldInputFile
            id={DocumentFileTypeEnum.BACK_CI}
            label="Foto de tu identificación (Detrás)"
            allowedMimeTypes={ALLOWED_MIME_TYPES}
            classNameInputButton={classes.inputField}
            splitLength={40}
            required
            form="novalidateform"
            file={defaultValues?.[DocumentFileTypeEnum.BACK_CI]}
            errorMessage={errors[DocumentFileTypeEnum.BACK_CI]?.message}
            {...methods.register(DocumentFileTypeEnum.BACK_CI)}
          />
          <FieldInputFile
            id={DocumentFileTypeEnum.SELFIE_CI}
            label="Foto de tu cara y de tu identificación donde se vea tu foto"
            classNameInputButton={classes.inputField}
            allowedMimeTypes={ALLOWED_MIME_TYPES}
            splitLength={40}
            required
            form="novalidateform"
            file={defaultValues?.[DocumentFileTypeEnum.SELFIE_CI]}
            errorMessage={errors[DocumentFileTypeEnum.SELFIE_CI]?.message}
            {...methods.register(DocumentFileTypeEnum.SELFIE_CI)}
          />
        </FormWrapper>
        <CallToAction label="Continuar" isLoading={isLoading} />
      </form>
    </FormProvider>
  );
};

export default IdentificationFiles;
