import axios, { AxiosResponse } from "axios";
import qs from "query-string";

import { IProperty, AutoProfilePropertyParsed } from "models";
import { REV_URL, BACKEND_URL } from "env";
import { PropertyData } from "models/Property";

import { getToken, createHttp, createNoAuthHttp } from "../helper";
import { CreateLanlordPropertyResponse, PostPropertyBody } from "./types";

interface IBasicServiceParams {
  country: string;
  ordering?: string;
}

const qsConfig = { skipEmptyString: true, skipNull: true };

export default {
  index(token) {
    return createHttp(token).get("/propietary/v2/properties/");
  },

  getPropertyBasicServices(id, token) {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token || getToken()}`,
    };
    const http = axios.create({
      baseURL: BACKEND_URL,
      headers,
    });
    return http.get(
      `/properties/v2/property_basic_services/?property_id=${id}`
    );
  },

  getBasicServices(token, country = "Chile", ordering = "") {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token || getToken()}`,
    };
    const params: IBasicServiceParams = { country };
    if (ordering !== "") params.ordering = ordering;
    const http = axios.create({
      baseURL: BACKEND_URL,
      headers,
      params,
    });
    return http.get("/properties/v2/basic_services");
  },

  attachService(data, token) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token || getToken()}`,
    };
    const http = axios.create({
      baseURL: BACKEND_URL,
      headers,
    });
    return http.post("/properties/v2/property_basic_services/", data);
  },

  get(id: string, token: string): Promise<AxiosResponse<PropertyData>> {
    return createHttp(token).get(`/propietary/v2/properties/${id}/`);
  },

  getPropertyDetails(property_id, token) {
    return createHttp(token).get(
      `schedules/v2/get_property_details/${property_id}`
    );
  },

  update(uid: string, data: IProperty | PropertyData, token: string) {
    return createHttp(token).put(`/propietary/v2/properties/${uid}/`, data);
  },

  updateRentalDetails(
    id: number,
    rentalData: { readjust: string; plan_id: number },
    token: string
  ) {
    return createHttp(token).put(
      `/properties/v2/${id}/rental_details/`,
      rentalData
    );
  },

  updateDetails(id, data, token) {
    return createHttp(token).put(
      `/propietary/v2/property_details/${id}/`,
      data
    );
  },

  updateSalesDetails(id, data, token) {
    return createHttp(token).patch(`/properties/v2/sales_details/${id}/`, data);
  },

  async getScheduledPhotos(propertyUid: string, token: string) {
    return createHttp(token).get(
      `/propietary/v2/properties/${propertyUid}/photo_scheduled/`
    );
  },

  newMarketplaceStatistics(propertyUid: string, token: string) {
    return createHttp(token).get(
      `/propietary/v2/properties/${propertyUid}/marketplace_stats/`
    );
  },

  async getAvailableNeighborhoods(country, token) {
    return createHttp(token).get(
      `/properties/v2/locations/?country=${country}`
    );
  },

  async updateDocuments(id, token, data) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token || getToken()}`,
    };

    const http = axios.create({ baseURL: BACKEND_URL, headers });
    return http.put(`/properties/v2/${id}/documents`, data);
  },

  async getDocuments(id, token) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token || getToken()}`,
    };
    const http = axios.create({ baseURL: BACKEND_URL, headers });
    return http.get(`/properties/v2/${id}/documents`);
  },

  async valuate(params) {
    const http = axios.create({ baseURL: REV_URL });
    return http.post("valuation/", params, {
      timeout: 1000 * 10,
    });
  },

  async listProperties({
    lang,
    lat,
    lng,
    mode,
    type,
    pageSize = 500,
    bounds,
    token,
  }) {
    const http = axios.create({
      baseURL: `${BACKEND_URL}`,
    });
    const params: any = {
      page_size: pageSize,
    };
    if (lat && lng) {
      params.coordinates = `${lat},${lng}`;
    }
    if (type === "forSale" || type === "sale") {
      params.for_sale = true;
      params.mode = "sale";
    }
    if (type === "forRent" || type === "rent") {
      params.for_rental = true;
      params.mode = "rent";
    }
    if (mode && ["departamento", "casa", "apartemento"].includes(mode)) {
      params.type = mode;
    }
    if (bounds?.length > 1) {
      const sw = bounds[0];
      const ne = bounds[1];
      params.boundings = `[[${sw[0]},${sw[1]}],[${ne[0]}, ${ne[1]}]]`;
    }
    if (lang) {
      params.country = lang;
    }
    return http.get("properties/marketplace/", { params });
  },

  async getContractPropertyInfo(id: number | string, token: string) {
    return createHttp(token).get(
      `/propietary/v2/properties/${id}/contract_form`
    );
  },

  async updateContractPropertyInfo(id: number, data, token: string) {
    return createHttp(token).put(
      `/propietary/v2/properties/${id}/contract_form`,
      data
    );
  },

  // Endpoint to know if a property is in avaliable area (macrozone)
  isWithinCoverage(latitude: number, longitude: number, token: string) {
    return createHttp(token, `${BACKEND_URL}`).get(
      `/properties/v2/is-within-coverage-area?latitude=${latitude}&longitude=${longitude}`
    );
  },
  // Endpoint to create a invalid property
  createInvalidProperty(data: AutoProfilePropertyParsed, token: string) {
    return createHttp(token).post("/propietary/v2/invalid_properties/", data);
  },
  // Endpoint to create a valid property
  createLandlordProperty(
    data: PostPropertyBody,
    token: string
  ): CreateLanlordPropertyResponse {
    return createHttp(token).post("propietary/v2/properties/", data);
  },
  getLocationsByLocale(locale: string): Promise<
    AxiosResponse<{
      regions: Record<string, { communes: Record<string, string> }>[];
    }>
  > {
    return createNoAuthHttp(BACKEND_URL).get(
      `/properties/get_country_locations?country_code=${locale}`
    );
  },
  // get landlord similar properties
  getLandlordSimilarProperties(
    id: number,
    type: "rent" | "sale",
    token: string
  ) {
    //Query params type: rent or sale, max_distance_treshold: max number of similar properties, top_k: max distance to consider similar properties
    const filters = {
      type,
      top_k: 4,
      max_distance_treshold: 25,
    };
    return createHttp(token).get(
      `/properties/v2/${id}/similar-properties-prices?${qs.stringify(
        filters,
        qsConfig
      )}`
    );
  },
  // get landlord similar external properties
  getLandlordSimilarExternalProperties(
    id: number,
    type: "rent" | "sale",
    token: string
  ) {
    //Query params type: rent or sale, max_distance_treshold: max number of similar properties, top_k: max distance to consider similar properties
    const filters = {
      type,
      top_k: 4,
      max_distance_treshold: 25,
    };
    return createHttp(token).get(
      `/properties/v2/${id}/similar-external-properties-prices?${qs.stringify(
        filters,
        qsConfig
      )}`
    );
  },
};
