import { useEffect } from "react";
import { useMutation } from "react-query";

import { alert } from "@houm-com/ui/Alert";

import { parserFiles } from "apps/lead/pages/EvaluatorForm/hooks/useUpdateDocuments/utils/parsers";
import evaluationService from "apps/lead/services/EvaluationService";
import { Locale } from "models/Countries";

interface Props {
  memberId: string;
  country: Locale;
}

const useUpdateDocuments = ({ memberId, country }: Props) => {
  const queryMutate = useMutation(
    async (data: { [key: string]: FileList[] }) => {
      const parsedData = parserFiles(data);
      return evaluationService.uploadFiles(memberId, country, parsedData);
    }
  );

  useEffect(() => {
    if (queryMutate.isError)
      alert.error({
        message: "¡Ocurrió un error!",
        disclaimer: "Por favor, intenta de nuevo más tarde",
      });
  }, [queryMutate.isError]);

  return queryMutate;
};

export default useUpdateDocuments;
