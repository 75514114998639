import { IoAddOutline } from "@houm-com/ui-icons";
import LightButton from "@houm-com/ui/LightButton";
import Tag from "@houm-com/ui/Tag";

import MemberCardBase from "apps/lead/pages/EvaluatorForm/components/EvaluationSummary/components/MemberCardBase";
import MemberInformation from "apps/lead/pages/EvaluatorForm/components/EvaluationSummary/components/MemberInformation";
import {
  EvaluationMember,
  EvaluationStatusEnum,
  StatusMemberEnum,
} from "apps/lead/models/Evaluation";
import {
  parserApplicant,
  parseUser,
} from "apps/lead/pages/EvaluatorForm/utils/parsers";
import { classes } from "apps/lead/pages/EvaluatorForm/components/EvaluationSummary/components/MemberCardSummary/MemberCardSummaryStyles";
import { useEvaluation } from "apps/lead/context/evaluatorContext/EvaluatorContext";
import {
  setPage,
  setUserInformation,
} from "apps/lead/context/evaluatorContext/EvaluatorActions";
import { EvaluatorPages } from "apps/lead/context/evaluatorContext/EvaluatorTypes";
import {
  EvaluationMemberStatusDictionary,
  statusVariant,
} from "./utils/constants";

interface Props {
  member: EvaluationMember;
  isDisabled?: boolean;
}

const MemberCardSummary = ({ member, isDisabled = false }: Props) => {
  const {
    dispatch,
    state: { evaluation },
  } = useEvaluation();
  const onHandleUploadDocuments = () => {
    dispatch(setUserInformation(parserApplicant(member)));
    dispatch(setPage(EvaluatorPages.DOCUMENTS));
  };

  const shouldRenderButton = () => {
    if (evaluation.status === EvaluationStatusEnum.APPROVED) return false;
    if (
      [
        StatusMemberEnum.REJECTED,
        StatusMemberEnum.DESISTED,
        StatusMemberEnum.COMMERCIALLY_APPROVED,
      ].includes(member.status)
    )
      return false;
    return true;
  };

  return (
    <MemberCardBase
      isMember={!!member}
      isDisabled={isDisabled}
      role={member.role}
      isHovered={shouldRenderButton()}
      statusTag={
        <Tag
          label={EvaluationMemberStatusDictionary[member.status]}
          variant={statusVariant[member.status]}
        />
      }
    >
      <MemberInformation user={parseUser(member)} />
      {shouldRenderButton() && (
        <LightButton
          size="lg"
          variant="primary"
          icon={<IoAddOutline />}
          className={classes.memberCardSummaryButton}
          onClick={onHandleUploadDocuments}
          disabled={isDisabled}
        >
          Subir documentos
        </LightButton>
      )}
    </MemberCardBase>
  );
};

export default MemberCardSummary;
