import { CountryUpperCase, Locale } from "models/Countries";
import { DocumentType } from "models/Document";
import { createHttp, createNoAuthHttp } from "../helper";
import { docTypesCL, docTypesCO, docTypesMX } from "./constants";

export default {
  getList: async (country: CountryUpperCase | string, token: string) =>
    createHttp(token)
      .get(`/v2/document_type/?country=${country}`)
      .catch((err) => console.error(err)),

  getDocumentListAuth: async (country: CountryUpperCase, token: string) =>
    createHttp(token).get(`/v2/document_type?country=${country}`),

  getDocumentList: async (country: CountryUpperCase) =>
    createNoAuthHttp().get(`/document_type?country=${country}`),

  getDocumentsPublic: async (country: Locale): Promise<DocumentType[]> =>
    new Promise((resolve) =>
      // eslint-disable-next-line no-promise-executor-return
      resolve(
        {
          cl: docTypesCL,
          co: docTypesCO,
          mx: docTypesMX,
        }[country]
      )
    ),
};
