import { useCallback, useState } from "react";

import { Grid } from "@material-ui/core";
import { isBrowser } from "react-device-detect";
import useSubscriptionPlanOptionsStyles from "./SubscriptionPlanOptionsStyles";
import { readjustOptions } from "../../constants";
import SubscriptionPlanCard from "../SubscriptionPlanCard";
import SelectPlanDialog from "../SelectPlanDialog";
import ReadjustmentDialog from "../ReadjustmentDialog";
import PlanInfo from "../../models";

interface Props {
  selectedPlan: PlanInfo | null;
  setSelectedPlan: (plan: PlanInfo) => void;
  setSubmitted: (submitted: boolean) => void;
  propertyId: number;
  plansList: PlanInfo[];
  brokerPlan: PlanInfo;
}

export default function SubscriptionPlanOptions({
  selectedPlan,
  setSelectedPlan,
  setSubmitted,
  propertyId,
  plansList,
  brokerPlan,
}: Props) {
  const classes = useSubscriptionPlanOptionsStyles();
  const [loading, setLoading] = useState(false);
  const [openSelectPlanDialog, setOpenSelectPlanDialog] = useState(false);
  const [openReadjustmentDialog, setOpenReadjustmentDialog] = useState(false);
  const [selectedReadjustment, setSelectedReadjustment] = useState("annual");
  const [preSelectedPlan, setPreSelectedPlan] = useState<PlanInfo | null>(null);
  const dialogSubtitle = `Has seleccionado el plan ${selectedPlan?.name}`;

  const handleClick = useCallback(
    (plan: PlanInfo) => {
      setPreSelectedPlan(plan);
      setOpenSelectPlanDialog(true);
    },
    [preSelectedPlan]
  );

  const handleReadjustmentChange = useCallback((option: string) => {
    setSelectedReadjustment(option);
  }, []);

  const handlePlanSelected = useCallback(() => {
    setOpenSelectPlanDialog(false);
    setOpenReadjustmentDialog(true);
  }, []);

  return (
    <Grid container>
      <SelectPlanDialog
        open={openSelectPlanDialog}
        setOpen={setOpenSelectPlanDialog}
        subtitle={dialogSubtitle}
        handleSubmit={handlePlanSelected}
        loading={loading}
      />
      <ReadjustmentDialog
        open={openReadjustmentDialog}
        setOpen={setOpenReadjustmentDialog}
        options={readjustOptions}
        handleChange={handleReadjustmentChange}
        value={selectedReadjustment}
        propertyId={propertyId}
        setLoading={setLoading}
        preSelectedPlan={preSelectedPlan}
        setSelectedPlan={setSelectedPlan}
        setSubmitted={setSubmitted}
      />
      <Grid item container xs={12} className={classes.componentContainer}>
        {plansList?.map((plan) => (
          <SubscriptionPlanCard
            key={plan.id}
            plan={plan}
            handleClick={() => handleClick(plan)}
          />
        ))}
      </Grid>
      <Grid item container>
        <SubscriptionPlanCard
          plan={brokerPlan}
          key={brokerPlan?.id}
          handleClick={() => handleClick(brokerPlan)}
          isHorizontal={isBrowser}
        />
      </Grid>
    </Grid>
  );
}
