import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";

import { useFeatureManager } from "@houm-com/feature-manager";

import useGetContractProperty from "apps/landlord/hooks/useGetContractProperty";
import { parseProperty } from "context/landlordPropertyContext/utils";
import PropertyLayout from "apps/landlord/Layout/PropertyLayout";
import useGetProperty from "apps/landlord/hooks/useGetProperty";
import Spinner from "Components/Shared/partials/Spinner";
import { SubscriptionPlan } from "models/Subscription";
import Navbar from "Components/Layout/GlobalHeader";

import SubscriptionPlanSelected from "./components/SubscriptionPlanSelected";
import SubscriptionPlanOptions from "./components/SubscriptionPlanOptions";
import SubscriptionPlansHeader from "./components/Header";

const SubscriptionPlans = () => {
  const params = useParams<{ id: string }>();
  const history = useHistory();
  const {
    isLoading: propertyLoading,
    data: property,
    error: propertyError,
  } = useGetProperty(params.id);
  const {
    isLoading: contractLoading,
    data: contract,
    error: contractError,
  } = useGetContractProperty(params.id);
  const { data, status: plansInfoStatus } = useFeatureManager("plans_info", {
    marketCode: "cl",
  });
  const plansInfo = data?.content;
  const [selectedPlan, setSelectedPlan] = useState<
    SubscriptionPlan | null | undefined
  >(null);
  const [submitted, setSubmitted] = useState(false);
  const availablePlansId = plansInfo?.plansList.map((p) => p.id);

  if (propertyError) {
    Swal.fire(
      "Ha ocurrido un error inesperado",
      "Por favor, vuelva a intentarlo o contacte a su ejecutivo",
      "error"
    );
    history.push(`/landlord/properties/${params.id}`);
  }

  if (contractLoading || propertyLoading || plansInfoStatus === "loading") {
    return (
      <>
        <Navbar />
        <Spinner />
      </>
    );
  }

  if (!["cl", "Chile"].includes(property?.country)) {
    history.push(`/landlord/properties/${params.id}`);
  }
  const propertyData = parseProperty(property);
  const hasCustomPlan =
    !(contractError || !contract?.plan) &&
    !availablePlansId.includes(contract?.plan?.id);
  if (contractError || !contract?.plan) {
    if (propertyData.plan && !selectedPlan) {
      setSelectedPlan(
        plansInfo?.plansList.find((p) => p.id === propertyData?.plan?.id)
      );
    }
  } else if (availablePlansId.includes(contract?.plan?.id) && !selectedPlan) {
    setSelectedPlan(
      plansInfo?.plansList.find((p) => p.id === contract?.plan?.id)
    );
  }
  const hasPlanSelected = !!selectedPlan || submitted || hasCustomPlan;

  return (
    <PropertyLayout
      titlePage="Plan de suscripción"
      onHandleBack={() => history.push(`/landlord/properties/${params.id}`)}
    >
      <SubscriptionPlansHeader hasPlan={hasPlanSelected} />
      {!hasPlanSelected ? (
        <SubscriptionPlanOptions
          propertyId={propertyData.id}
          selectedPlan={selectedPlan}
          setSelectedPlan={setSelectedPlan}
          setSubmitted={setSubmitted}
          plansList={plansInfo?.plansList}
          brokerPlan={plansInfo?.brokerPlan}
        />
      ) : (
        <SubscriptionPlanSelected
          isCustom={hasCustomPlan}
          contract={contract}
          plan={selectedPlan}
        />
      )}
    </PropertyLayout>
  );
};

export default SubscriptionPlans;
