import { UserApplicant } from "apps/lead/context/evaluatorContext/EvaluatorTypes";
import { MemberPersonalInformation } from "apps/lead/models/Evaluation";

export const parseDefaultData = (userInformation: Partial<UserApplicant>) => ({
  isAddressSelected: !!userInformation.extraInformation?.fullAddress,
  fullAddress: userInformation.extraInformation?.fullAddress,
});

export const parseAddressData = (
  userInformation: Partial<UserApplicant>,
  data: MemberPersonalInformation
) => ({
  ...userInformation,
  extraInformation: {
    ...userInformation.extraInformation,
    fullAddress: data.address.fullAddress,
  },
});
