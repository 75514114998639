import { ChangeEvent } from "react";
import { useFormContext } from "react-hook-form";
import { format } from "rut.js";

import FieldSelect from "@houm-com/ui/FieldSelect";
import FieldInput from "@houm-com/ui/FieldInput";

import useCountryList from "apps/lead/components/EvaluatorForm/ApplicantFields/hooks/useCountryList";
import { IApplicantForm } from "apps/lead/pages/EvaluatorForm/components/ApplicantForm/utils/types";
import { DocumentTypes } from "hooks/useDocumentTypeListPublic/utils/types";
import PhoneField from "apps/lead/components/PhoneField";
import { Locale } from "models/Countries";

interface Props {
  country: Locale;
  documentList: DocumentTypes[];
  emailDisabled?: boolean;
}

const ApplicantFields = ({
  country,
  documentList,
  emailDisabled = false,
}: Props) => {
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext<IApplicantForm>();
  const { countries } = useCountryList();

  const handleDocumentChange = (e: ChangeEvent<HTMLInputElement>) => {
    const formatDocument = e.target.value.toUpperCase();
    if (country === "cl") return setValue("document", format(e.target.value));
    return setValue("document", formatDocument);
  };

  return (
    <div className="flex flex-col gap-4">
      <FieldInput
        id="name"
        label="Nombre"
        placeholder="Escribe tu nombre aquí"
        required
        errorMessage={errors.name?.message}
        form="novalidateform"
        {...register("name")}
      />
      <FieldInput
        id="lastName"
        label="Apellido paterno"
        placeholder="Escribe tu apellido paterno"
        required
        errorMessage={errors.lastName?.message}
        form="novalidateform"
        {...register("lastName")}
      />
      <FieldInput
        id="secondLastName"
        label="Apellido materno"
        placeholder="Escribe tu apellido materno"
        required
        errorMessage={errors.secondLastName?.message}
        form="novalidateform"
        {...register("secondLastName")}
      />
      <FieldSelect
        id="documentType"
        label="Tipo de documento"
        placeholder="Seleccionar una opción"
        options={documentList}
        required
        errorMessage={errors.documentType?.message}
        form="novalidateform"
        {...register("documentType")}
      />
      <FieldInput
        id="document"
        label="Número de documento"
        placeholder="0000000000"
        required
        errorMessage={errors.document?.message}
        form="novalidateform"
        {...register("document")}
        onChange={handleDocumentChange}
      />
      <FieldSelect
        id="nationality"
        label="Nacionalidad"
        placeholder="Seleccionar una opción"
        required
        options={countries}
        errorMessage={errors.nationality?.message}
        form="novalidateform"
        {...register("nationality")}
      />
      <FieldInput
        id="email"
        label="Correo electrónico"
        placeholder="ejemplo@houm.com"
        required
        errorMessage={errors.email?.message}
        readOnly={emailDisabled}
        form="novalidateform"
        {...register("email")}
      />
      <PhoneField />
    </div>
  );
};

export default ApplicantFields;
