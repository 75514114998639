import { object } from "yup";

import { checkFilesLength } from "apps/lead/pages/EvaluatorForm/components/IncomeValidationFiles/utils/validationSchema";
import { DocumentFileTypeEnum } from "apps/lead/context/evaluatorContext/EvaluatorTypes";

export const requiredText = "Obligatorio";

export const independentFilesSchema = object().shape({
  [DocumentFileTypeEnum.BANK_STATEMENT]: checkFilesLength(),
});
