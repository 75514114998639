import { cn } from "@houm-com/ui/utils";
import { MemberRole } from "apps/lead/models/Evaluation";

import { classes } from "apps/lead/pages/EvaluatorForm/components/EvaluationSummary/components/MemberCardInit/components/MemberCardInformation/MemberCardInformationStyles";
import { memberTypeInfo } from "apps/lead/pages/EvaluatorForm/components/EvaluationSummary/components/MemberCardSummary/utils/constants";

interface Props {
  isDisabled?: boolean;
  role: MemberRole;
}

const MemberCardInformation = ({ role, isDisabled = false }: Props) => (
  <div className={classes.memberCardInfoContainer}>
    <p className={classes.memberCardInfoText}>
      {memberTypeInfo[role].information}
    </p>
    {memberTypeInfo[role].disclaimer && (
      <span
        className={cn([
          classes.memberCardDisclaimerText,
          [{ [classes.memberCardDisclaimerDisabled]: isDisabled }],
        ])}
      >
        {memberTypeInfo[role].disclaimer}
      </span>
    )}
  </div>
);

export default MemberCardInformation;
