import { DocumentFileTypeEnum } from "apps/lead/context/evaluatorContext/EvaluatorTypes";

export const parserApplicantDocuments = (data: {
  [K in DocumentFileTypeEnum]: FileList[];
}) => ({
  identityFiles: {
    [DocumentFileTypeEnum.FRONT_CI]: data?.[DocumentFileTypeEnum.FRONT_CI],
    [DocumentFileTypeEnum.BACK_CI]: data?.[DocumentFileTypeEnum.BACK_CI],
    [DocumentFileTypeEnum.SELFIE_CI]: data?.[DocumentFileTypeEnum.SELFIE_CI],
  },
  employmentFiles: {
    [DocumentFileTypeEnum.EMPLOYMENT_CERTIFICATE]:
      data?.[DocumentFileTypeEnum.EMPLOYMENT_CERTIFICATE],
    [DocumentFileTypeEnum.TAX_DECLARATION]:
      data?.[DocumentFileTypeEnum.TAX_DECLARATION],
    [DocumentFileTypeEnum.COMPANY_INCOME_STATEMENT]:
      data?.[DocumentFileTypeEnum.COMPANY_INCOME_STATEMENT],
    [DocumentFileTypeEnum.COMPANY_CONSTITUTION]:
      data?.[DocumentFileTypeEnum.COMPANY_CONSTITUTION],
    [DocumentFileTypeEnum.PENSIONER_CERTIFICATE]:
      data?.[DocumentFileTypeEnum.PENSIONER_CERTIFICATE],
  },
  incomeValidationFiles: {
    [DocumentFileTypeEnum.PENSION_SALARY_SETTLEMENT]:
      data?.[DocumentFileTypeEnum.PENSION_SALARY_SETTLEMENT],
    [DocumentFileTypeEnum.SALARY_SETTLEMENT]:
      data?.[DocumentFileTypeEnum.SALARY_SETTLEMENT],
    [DocumentFileTypeEnum.BANK_STATEMENT]:
      data?.[DocumentFileTypeEnum.BANK_STATEMENT],
  },
});
