import { Grid, Typography } from "@material-ui/core";

import ControlledPhoneInput from "apps/lead/components/ControlledForm/ControlledPhoneInput";
import { classes } from "apps/lead/components/PhoneField/PhoneFieldStyles";
import { PhoneInput } from "models/PhoneInput";

interface Props {
  defaultValue?: PhoneInput;
}

const PhoneField = ({ defaultValue }: Props) => (
  <Grid item xs={12}>
    <ControlledPhoneInput
      id="phone"
      phoneLabel={
        <Typography className={classes.textFieldLabel}>
          Número de celular <strong>*</strong>
        </Typography>
      }
      classNameInputContainer={classes.inputContainer}
      defaultValue={defaultValue}
      numberInputProps={{
        InputProps: {
          disableUnderline: true,
          classes: {
            root: classes.textInput,
          },
          placeholder: "55 55 55 55",
        },
        inputProps: {
          className: classes.inputProps,
        },
      }}
      countryWrapperClassName={classes.phoneWrapper}
      numberWrapperClassName={classes.phoneWrapper}
      countryInputProps={{
        InputProps: {
          disableUnderline: true,
          classes: {
            root: classes.countryCodeInput,
          },
        },
        inputProps: {
          className: classes.inputProps,
        },
      }}
    />
  </Grid>
);
export default PhoneField;
