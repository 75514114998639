import zod from "zod";

export enum MemberRoleEnum {
  MAIN = "main",
  CODEBTOR = "codebtor",
  COMPLEMENT = "rent_complement",
}

export enum EvaluationStatusEnum {
  PENDING = "pending",
  APPROVED = "approved",
  REJECTED = "rejected",
  CANCELLED = "cancelled",
  INCOMPLETE = "incomplete",
  PARTIALLY_APPROVED = "partially_approved",
}

export const EvaluationStatusListEnum = [
  EvaluationStatusEnum.PENDING,
  EvaluationStatusEnum.APPROVED,
  EvaluationStatusEnum.REJECTED,
  EvaluationStatusEnum.CANCELLED,
  EvaluationStatusEnum.INCOMPLETE,
  EvaluationStatusEnum.PARTIALLY_APPROVED,
] as const;

export const EvaluationStatusSchema = zod.enum(EvaluationStatusListEnum);

export enum DocumentFileTypeEnum {
  FRONT_CI = "front_ci",
  BACK_CI = "back_ci",
  SELFIE_CI = "selfie_ci",
  BANK_STATEMENT = "bank_statement",
  PENSION_SALARY_SETTLEMENT = "pension_salary_settlement",
  PENSIONER_CERTIFICATE = "pensioner_certificate",
  EMPLOYMENT_CERTIFICATE = "employment_certificate",
  SALARY_SETTLEMENT = "salary_settlement",
  COMPANY_CONSTITUTION = "company_constitution",
  COMPANY_INCOME_STATEMENT = "company_income_statement",
  TAX_DECLARATION = "tax_declaration",
  UNKNOWN = "unknown",
  OTHER = "other",
}

export const DocumentFileTypeListEnum = [
  DocumentFileTypeEnum.FRONT_CI,
  DocumentFileTypeEnum.BACK_CI,
  DocumentFileTypeEnum.BANK_STATEMENT,
  DocumentFileTypeEnum.PENSION_SALARY_SETTLEMENT,
  DocumentFileTypeEnum.PENSIONER_CERTIFICATE,
  DocumentFileTypeEnum.EMPLOYMENT_CERTIFICATE,
  DocumentFileTypeEnum.SALARY_SETTLEMENT,
  DocumentFileTypeEnum.COMPANY_CONSTITUTION,
  DocumentFileTypeEnum.COMPANY_INCOME_STATEMENT,
  DocumentFileTypeEnum.TAX_DECLARATION,
  DocumentFileTypeEnum.SELFIE_CI,
  DocumentFileTypeEnum.UNKNOWN,
  DocumentFileTypeEnum.OTHER,
] as const;

export const DocumentFileTypeSchema = zod.enum(DocumentFileTypeListEnum);

export const UserProfileSchema = zod
  .object({
    id: zod.number(),
    name: zod.string(),
    last_name: zod.string(),
    second_last_name: zod.string(),
    phone: zod.string().nullable(),
    email: zod.string(),
    document: zod.string().nullable(),
    document_type: zod.number().nullable(),
    country: zod.string().nullable(),
    created_at: zod.string(),
  })
  .transform(
    ({ last_name, created_at, document_type, second_last_name, ...rest }) => ({
      lastName: last_name,
      createdAt: created_at,
      documentType: document_type,
      secondLastName: second_last_name,
      ...rest,
    })
  );

export const CommentTypeEnum = ["document_request", "simple_comment"] as const;

export const CommentTypeSchema = zod.enum(CommentTypeEnum);
export const EvaluationCommentSchema = zod
  .object({
    comment: zod.string().nullable(),
    comment_type: zod.nullable(CommentTypeSchema),
    commenter_name: zod.string().nullable(),
    commenter_id: zod.number(),
    created_at: zod.string(),
    id: zod.string(),
    updated_at: zod.string().nullable(),
  })
  .transform(
    ({
      comment_type,
      created_at,
      commenter_id,
      updated_at,
      commenter_name,
      ...rest
    }) => ({
      commentType: comment_type,
      createdAt: created_at,
      commenterId: commenter_id,
      updatedAt: updated_at,
      commenterName: commenter_name,
      ...rest,
    })
  );

export const EvaluationDocumentSchema = zod
  .object({
    content_type: zod.string().nullable(),
    created_at: zod.string(),
    document_type: zod.nullable(DocumentFileTypeSchema),
    filename: zod.string().nullable(),
    id: zod.string().nullable(),
    updated_at: zod.string().nullable(),
    url: zod.string().nullable(),
  })
  .transform(
    ({ content_type, created_at, document_type, updated_at, ...rest }) => ({
      contentType: content_type,
      createdAt: created_at,
      documentType: document_type,
      updatedAt: updated_at,
      ...rest,
    })
  );

export enum FactorProviderEnum {
  DATA_CREDITO = "datacredito",
  BURO = "buro",
  AVLA = "avla",
}
export const FactorProviderEnumList = [
  FactorProviderEnum.DATA_CREDITO,
  FactorProviderEnum.BURO,
  FactorProviderEnum.AVLA,
] as const;
export const FactorProvider = zod.enum(FactorProviderEnumList);

export enum FactorStatusEnum {
  RESOLVED = "resolved",
  PENDING = "pending",
  ONGOING = "ongoing",
}
export const FactorStatusEnumList = [
  FactorStatusEnum.RESOLVED,
  FactorStatusEnum.PENDING,
  FactorStatusEnum.ONGOING,
] as const;
export const FactorStatus = zod.enum(FactorStatusEnumList);

export enum FactorResultStatusEnum {
  ERROR = "error",
  APPROVED = "approved",
  REJECTED = "rejected",
  PENDING = "pending",
}
export const FactorResultStatusEnumList = [
  FactorResultStatusEnum.APPROVED,
  FactorResultStatusEnum.ERROR,
  FactorResultStatusEnum.REJECTED,
  FactorResultStatusEnum.PENDING,
] as const;
export const FactorResultStatus = zod.enum(FactorResultStatusEnumList);

const RawInput = zod
  .object({
    dni: zod.string(),
    document_type: zod.string(),
    last_name: zod.string(),
  })
  .nullable()
  .transform(({ document_type, last_name, ...rest }) => ({
    documentType: document_type,
    lastName: last_name,
    ...rest,
  }));

export const EvaluationFactorsSchema = zod
  .object({
    created_at: zod.string(),
    evaluation_member_id: zod.string(),
    events: zod.array(zod.any()),
    id: zod.string(),
    provider_name: FactorProvider,
    raw_input: zod.nullable(RawInput),
    raw_output: zod
      .object({
        message: zod.string(),
        score: zod.number(),
        status: zod.string(),
      })
      .nullable(),
    result: zod
      .object({
        score: zod.number().optional(),
        status: zod.nullable(FactorResultStatus).optional(),
      })
      .nullable(),
    status: zod.nullable(FactorStatus),
    updated_at: zod.string(),
  })
  .transform(
    ({
      created_at,
      evaluation_member_id,
      provider_name,
      raw_input,
      raw_output,
      updated_at,
      ...rest
    }) => ({
      createdAt: created_at,
      evaluationMemberId: evaluation_member_id,
      providerName: provider_name,
      rawInput: raw_input,
      rawOutput: raw_output,
      updatedAt: updated_at,
      ...rest,
    })
  );

const MemberRolesListEnum = [
  MemberRoleEnum.MAIN,
  MemberRoleEnum.CODEBTOR,
  MemberRoleEnum.COMPLEMENT,
] as const;
export const MemberRolesSchema = zod.enum(MemberRolesListEnum);

export enum StatusMemberEnum {
  COMMERCIALLY_APPROVED = "commercially_approved",
  DESISTED = "desisted",
  PENDING_DOCS = "pending_docs",
  READY_FOR_EVALUATION = "ready_for_evaluation",
  NEW_DOCS = "new_docs",
  APPROVED = "approved",
  REJECTED = "rejected",
  START = "start",
  NO_DOCS = "no_docs",
}

export const EvaluationMemberStatusEnum = [
  StatusMemberEnum.COMMERCIALLY_APPROVED,
  StatusMemberEnum.DESISTED,
  StatusMemberEnum.PENDING_DOCS,
  StatusMemberEnum.READY_FOR_EVALUATION,
  StatusMemberEnum.NEW_DOCS,
  StatusMemberEnum.APPROVED,
  StatusMemberEnum.REJECTED,
  StatusMemberEnum.START,
  StatusMemberEnum.NO_DOCS,
] as const;

export const EvaluationMemberStatusSchema = zod.enum(
  EvaluationMemberStatusEnum
);

export const IncomeTypeEnum = ["salary", "other"] as const;
export const IncomeTypeSchema = zod.enum(IncomeTypeEnum);
export const IdentificationSchema = zod
  .object({
    document_identifier: zod.string().nullable(),
    document_number: zod.string().nullable(),
    document_identifier_external_id: zod.number().nullable(),
  })
  .nullable()
  .transform(
    ({
      document_identifier,
      document_number,
      document_identifier_external_id,
    }) => ({
      documentIdentifier: document_identifier,
      documentNumber: document_number,
      documentIdentifierExternalId: document_identifier_external_id,
    })
  );

export const IncomeSchema = zod.array(
  zod
    .object({
      amount: zod.string().nullable(),
      money_type: zod.string().nullable(),
      month: zod.number().nullable(),
      type: IncomeTypeSchema,
      description: zod.string().nullable(),
    })
    .transform(({ money_type, ...rest }) => ({
      moneyType: money_type,
      ...rest,
    }))
);

const AddressSchema = zod
  .object({
    // city: zod.string().nullable(),
    // street: zod.string().nullable(),
    full_address: zod.string().nullable(),
  })
  .transform(({ full_address }) => ({ fullAddress: full_address }));

export const EvaluationVisaEnum = [
  "definitive",
  "student",
  "temporary",
  "",
] as const;
export const VisaTypeSchema = zod.enum(EvaluationVisaEnum);

export enum EmploymentContractEnum {
  FIXED = "fixed_term",
  PERMANENT = "permanent",
  EMPTY = "",
}

const EmploymentContractListEnum = [
  EmploymentContractEnum.PERMANENT,
  EmploymentContractEnum.EMPTY,
] as const;

export const EmploymentContractSchema = zod.enum(EmploymentContractListEnum);

export enum IncomeSourceTypeEnum {
  DEPENDENT = "dependent",
  INDEPENDENT = "independent",
  RETIRED = "retired",
  EMPTY = "",
}

const IncomeSourceTypeListEnum = [
  IncomeSourceTypeEnum.DEPENDENT,
  IncomeSourceTypeEnum.INDEPENDENT,
  IncomeSourceTypeEnum.RETIRED,
  IncomeSourceTypeEnum.EMPTY,
] as const;

export const IncomeSourceTypeSchema = zod.enum(IncomeSourceTypeListEnum);

export const MemberPersonalInformationSchema = zod
  .object({
    address: zod.nullable(AddressSchema),
    comment: zod.string().nullable(),
    // created_at: zod.string(),
    employer: zod.string().nullable(),
    employer_phone: zod.string().nullable(),
    employment_contract_type: EmploymentContractSchema,
    id: zod.string(),
    identification: zod.nullable(IdentificationSchema),
    identifications: zod.array(IdentificationSchema),
    income_source_type: IncomeSourceTypeSchema,
    // updated_at: zod.string(),
    name: zod.string().nullable(),
    nationality: zod.string().nullable(),
    occupation: zod.string().nullable(),
    incomes: IncomeSchema,
    visa_type: zod.nullable(VisaTypeSchema),
  })
  .transform(
    ({
      employment_contract_type,
      income_source_type,
      visa_type,
      employer_phone,
      ...rest
    }) => ({
      employmentContractType: employment_contract_type,
      employerPhone: employer_phone,
      incomeSourceType: income_source_type,
      visaType: visa_type,
      ...rest,
    })
  );

export const EvaluationMemberSchema = zod
  .object({
    comments: zod.array(EvaluationCommentSchema),
    documents: zod.array(EvaluationDocumentSchema),
    external_id: zod.number().nullable(),
    factors: zod.array(EvaluationFactorsSchema),
    incomes_avg: zod.string(),
    is_form_completed: zod.boolean(),
    member_id: zod.string(),
    role: MemberRolesSchema,
    status: EvaluationMemberStatusSchema,
    person_information: MemberPersonalInformationSchema,
    user_data: UserProfileSchema,
  })
  .transform(
    ({
      external_id,
      member_id,
      person_information,
      user_data,
      incomes_avg,
      is_form_completed,
      ...rest
    }) => ({
      externalId: external_id,
      memberId: member_id,
      personInformation: person_information,
      userData: user_data,
      incomesAvg: incomes_avg,
      isFormCompleted: is_form_completed,
      ...rest,
    })
  );

export const EvaluationSchema = zod
  .object({
    applicant_id: zod.string().nullable(),
    brokerage: zod.boolean(),
    created_at: zod.string(),
    id: zod.string(),
    incomes_sum: zod.string().nullable(),
    members: zod.array(EvaluationMemberSchema),
    property_id: zod.number().nullable(),
    property_uid: zod.string().nullable(),
    status: EvaluationStatusSchema,
    updated_at: zod.string().nullable(),
  })
  .transform(
    ({
      applicant_id,
      created_at,
      property_id,
      property_uid,
      updated_at,
      incomes_sum,
      members,
      ...rest
    }) => ({
      applicantId: applicant_id,
      createdAt: created_at,
      propertyId: property_id,
      updatedAt: updated_at,
      propertyUid: property_uid,
      salariesSum: incomes_sum,
      members: members.sort((a, b) => {
        if (a.role === MemberRoleEnum.MAIN) return -1;
        if (b.role === MemberRoleEnum.MAIN) return 1;
        return 0;
      }),
      ...rest,
    })
  );

export type Evaluation = zod.infer<typeof EvaluationSchema>;
export type EmploymentContractType = zod.infer<typeof EmploymentContractSchema>;
export type MemberRole = zod.infer<typeof MemberRolesSchema>;
export type EvaluationMember = zod.infer<typeof EvaluationMemberSchema>;
export type EvaluationMemberUser = zod.infer<typeof UserProfileSchema>;
export type MemberPersonalInformation = zod.infer<
  typeof MemberPersonalInformationSchema
>;
export type IncomeSourceType = zod.infer<typeof IncomeSourceTypeSchema>;
export type EvaluationDocument = zod.infer<typeof EvaluationDocumentSchema>;
export type DocumentFileType = zod.infer<typeof DocumentFileTypeSchema>;
export type EvaluationMemberStatus = zod.infer<
  typeof EvaluationMemberStatusSchema
>;
