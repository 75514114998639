import { FormProvider, SubmitHandler } from "react-hook-form";

import FieldInputFile from "@houm-com/ui/FieldInputFile";

import { retiredFilesSchema } from "apps/lead/pages/EvaluatorForm/components/EmploymentValidationForm/components/co/RetiredForm/utils/validationSchema";
import { IRetiredFilesForm } from "apps/lead/pages/EvaluatorForm/components/EmploymentValidationForm/components/co/RetiredForm/utils/types";
import { classes } from "apps/lead/pages/EvaluatorForm/components/EmploymentValidationForm/components/co/RetiredForm/RetiredFormStyles";
import { useEvaluation } from "apps/lead/context/evaluatorContext/EvaluatorContext";
import CallToAction from "apps/lead/components/EvaluatorForm/CallToAction";
import FormWrapper from "apps/lead/components/EvaluatorForm/FormWrapper";
import useReactHookForm from "hooks/useReactHookForm";
import {
  parserDefaultData,
  parserMutationFiles,
} from "apps/lead/pages/EvaluatorForm/components/EmploymentValidationForm/components/co/RetiredForm/utils/parsers";
import { ALLOWED_MIME_TYPES } from "apps/lead/pages/EvaluatorForm/utils/constants";
import { DocumentFileTypeEnum } from "apps/lead/context/evaluatorContext/EvaluatorTypes";

interface Props {
  onUploadFiles: (data: Record<keyof IRetiredFilesForm, FileList[]>) => void;
  isLoading: boolean;
}

const RetiredForm = ({ onUploadFiles, isLoading }: Props) => {
  const {
    state: { user: userInformation },
  } = useEvaluation();

  const defaultValues = parserDefaultData(userInformation.employmentFiles);
  const { methods, errors } = useReactHookForm<IRetiredFilesForm>({
    formSchema: retiredFilesSchema,
    formOptions: {
      defaultValues,
    },
  });

  const onSubmit: SubmitHandler<IRetiredFilesForm> = async (data) => {
    if (isLoading) return;
    const transformedData = parserMutationFiles(data);
    onUploadFiles(transformedData);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormWrapper classNameFormWrapper={classes.retiredFormRoot}>
          <FieldInputFile
            id={DocumentFileTypeEnum.PENSIONER_CERTIFICATE}
            label="Certificado de pensión"
            allowedMimeTypes={ALLOWED_MIME_TYPES}
            classNameInputButton={classes.retiredFormInputFile}
            splitLength={40}
            required
            form="novalidateform"
            file={defaultValues[DocumentFileTypeEnum.PENSIONER_CERTIFICATE]}
            errorMessage={
              errors[DocumentFileTypeEnum.PENSIONER_CERTIFICATE]?.message
            }
            {...methods.register(DocumentFileTypeEnum.PENSIONER_CERTIFICATE)}
          />
        </FormWrapper>
        <CallToAction label="Continuar" isLoading={isLoading} />
      </form>
    </FormProvider>
  );
};

export default RetiredForm;
