import { startCase, toLower } from "lodash";

import { IApplicantForm } from "apps/lead/pages/EvaluatorForm/components/ApplicantForm/utils/types";
import { MemberData } from "apps/lead/pages/EvaluatorForm/utils/types";

export const getParseApplicant = (data: IApplicantForm): MemberData => ({
  ...data,
  email: data.email.toLowerCase(),
  name: startCase(toLower(data.name)),
  lastName: startCase(toLower(data.lastName)),
  secondLastName: startCase(toLower(data.secondLastName)),
  phone: data.phone.parsedPhone,
});
