import { User } from "apps/lead/context/evaluatorContext/EvaluatorTypes";
import { classes } from "apps/lead/pages/EvaluatorForm/components/EvaluationSummary/components/MemberInformation/MemberInformationStyles";

interface Props {
  user: User;
}

const MemberInformation = ({ user }: Props) => (
  <div className={classes.memberInformationFillInfo}>
    <p
      className={classes.memberInformationCardInfoText}
    >{`${user.name} ${user.lastName} ${user.secondLastName}`}</p>
    <p>{user.email}</p>
    <p>{user.phone}</p>
    <p>{user.nationality}</p>
    <p>{user.document}</p>
  </div>
);

export default MemberInformation;
