import Spinner from "@houm-com/ui/Spinner";

import DefaultTenantLayout from "apps/lead/layout/DefaultTenantLayout";
import EvaluatorForm from "apps/lead/pages/EvaluatorForm/EvaluatorForm";
import { classes } from "apps/lead/pages/EvaluatorForm/EvaluatorFormStyles";
import useEvaluationForm from "apps/lead/pages/EvaluatorForm/hooks/useEvaluationForm";

const EvaluationForm = () => {
  const { isLoading, isError } = useEvaluationForm();

  if (isLoading)
    return (
      <div className={classes.evaluatorFormRoot}>
        <Spinner variant="primary" size="2xl" />
      </div>
    );

  if (isError)
    return (
      <div className={classes.evaluatorFormRoot}>
        Ha ocurrido un error cargando la evaluación
      </div>
    );

  return (
    <DefaultTenantLayout>
      <EvaluatorForm />
    </DefaultTenantLayout>
  );
};

export default EvaluationForm;
