import { FormProvider, SubmitHandler } from "react-hook-form";

import FieldWrapper from "@houm-com/ui/FieldWrapper";
import InputFile from "@houm-com/ui/InputFileBase";

import { dependentFilesSchema } from "apps/lead/pages/EvaluatorForm/components/IncomeValidationFiles/components/co/DependentForm/utils/validationSchema";
import { IDependentFilesForm } from "apps/lead/pages/EvaluatorForm/components/IncomeValidationFiles/components/co/DependentForm/utils/types";
import CallToAction from "apps/lead/components/EvaluatorForm/CallToAction";
import useReactHookForm from "hooks/useReactHookForm";
import { transformToFileListArray } from "apps/lead/pages/EvaluatorForm/components/IncomeValidationFiles/utils/utils";
import useDefaultData from "apps/lead/pages/EvaluatorForm/components/IncomeValidationFiles/hooks/useDefaultData";
import { ALLOWED_MIME_TYPES } from "apps/lead/pages/EvaluatorForm/utils/constants";
import { DocumentFileTypeEnum } from "apps/lead/context/evaluatorContext/EvaluatorTypes";

interface Props {
  onFinishSubmit: (data: Record<keyof IDependentFilesForm, FileList[]>) => void;
  isLoading: boolean;
}

const DependentForm = ({ onFinishSubmit, isLoading }: Props) => {
  const { defaultFiles } = useDefaultData();
  const { methods, errors } = useReactHookForm<IDependentFilesForm>({
    formSchema: dependentFilesSchema,
  });
  const { setValue, getValues } = methods;

  const onSubmit: SubmitHandler<IDependentFilesForm> = (data) => {
    const transformedData = transformToFileListArray<IDependentFilesForm>(data);
    onFinishSubmit?.(transformedData);
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className="flex flex-col gap-4"
      >
        <FieldWrapper
          id={DocumentFileTypeEnum.SALARY_SETTLEMENT}
          label="Últimas 3 liquidaciones de sueldo"
          required
          errorMessage={
            errors[DocumentFileTypeEnum.SALARY_SETTLEMENT]
              ?.message as unknown as string
          }
        >
          <div className="flex flex-col gap-4">
            {[...Array(3).keys()].map((id) => (
              <InputFile
                key={id}
                id={`${DocumentFileTypeEnum.SALARY_SETTLEMENT}${id + 1}`}
                allowedMimeTypes={ALLOWED_MIME_TYPES}
                classNameInputButton="!w-full"
                splitLength={40}
                form="novalidateform"
                file={
                  defaultFiles?.[DocumentFileTypeEnum.SALARY_SETTLEMENT]?.[
                    `${DocumentFileTypeEnum.SALARY_SETTLEMENT}${id + 1}`
                  ]
                }
                onLoadFile={(e) => {
                  const salaryObject = {
                    ...getValues(DocumentFileTypeEnum.SALARY_SETTLEMENT),
                    [`${DocumentFileTypeEnum.SALARY_SETTLEMENT}${id + 1}`]: e,
                  };
                  setValue(
                    DocumentFileTypeEnum.SALARY_SETTLEMENT,
                    salaryObject
                  );
                }}
              />
            ))}
          </div>
        </FieldWrapper>
        <FieldWrapper
          id={DocumentFileTypeEnum.BANK_STATEMENT}
          label="Últimos 3 extractos bancarios"
          required
          errorMessage={
            errors[DocumentFileTypeEnum.BANK_STATEMENT]
              ?.message as unknown as string
          }
        >
          <div className="flex flex-col gap-4">
            {[...Array(3).keys()].map((id) => (
              <InputFile
                key={id}
                id={`${DocumentFileTypeEnum.BANK_STATEMENT}${id + 1}`}
                allowedMimeTypes={ALLOWED_MIME_TYPES}
                classNameInputButton="!w-full"
                splitLength={40}
                form="novalidateform"
                file={
                  defaultFiles?.[DocumentFileTypeEnum.BANK_STATEMENT]?.[
                    `${DocumentFileTypeEnum.BANK_STATEMENT}${id + 1}`
                  ]
                }
                onLoadFile={(e) => {
                  const bankObject = {
                    ...getValues(DocumentFileTypeEnum.BANK_STATEMENT),
                    [`${DocumentFileTypeEnum.BANK_STATEMENT}${id + 1}`]: e,
                  };
                  setValue(DocumentFileTypeEnum.BANK_STATEMENT, bankObject);
                }}
              />
            ))}
          </div>
        </FieldWrapper>
        <CallToAction label="Finalizar" isLoading={isLoading} />
      </form>
    </FormProvider>
  );
};

export default DependentForm;
