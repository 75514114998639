import axios from "axios";

import { Availability } from "utils/availabilityTime";

import { getToken, createNoAuthHttp } from "./helper";
import { BACKEND_URL } from "../env";

export default {
  async getAvailableHours(
    scheduleType,
    propId,
    token,
    appraiserId = undefined,
    country = null,
    cityId = null
  ) {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token || getToken()}`,
    };
    const http = axios.create({
      baseURL: BACKEND_URL,
      headers,
    });
    return http
      .get("/schedules/v2/get_available_hours", {
        params: {
          schedule_type: scheduleType,
          property: propId,
          appraiser: appraiserId,
          country,
          city_id: cityId,
        },
      })
      .catch((error) => {
        console.error(error);
        return false;
      });
  },

  createPhotoSchedule(schedule, token) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token || getToken()}`,
    };
    const http = axios.create({
      baseURL: BACKEND_URL,
      headers,
    });
    return http.post("/schedules/v2/create_schedule_user/", schedule);
  },

  getAvailabilityByLocation({
    lat,
    lng,
    type,
  }: {
    lat: number;
    lng: number;
    type: "Photo" | "Visit";
  }) {
    return createNoAuthHttp(BACKEND_URL).get<Availability>(
      "/schedules/available_hours_by_macrozone/",
      {
        params: { schedule_type: type, latitude: lat, longitude: lng },
      }
    );
  },
};
