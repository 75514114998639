import { useEffect } from "react";
import { useMutation } from "react-query";

import { alert } from "@houm-com/ui/Alert";

import { parserMemberRequest } from "apps/lead/pages/EvaluatorForm/utils/parsers";
import { MemberData } from "apps/lead/pages/EvaluatorForm/utils/types";
import evaluationService from "apps/lead/services/EvaluationService";
import { MemberRole } from "apps/lead/models/Evaluation";
import { Locale } from "models/Countries";

interface Props {
  evaluationId: string;
  country: Locale;
  memberRole: MemberRole;
}

const useCreateMember = ({ evaluationId, country, memberRole }: Props) => {
  const queryMutate = useMutation(async (data: MemberData) => {
    const parsedData = {
      person_data: parserMemberRequest(data),
      role: memberRole,
    };
    return evaluationService.createMember(evaluationId, country, parsedData);
  });

  useEffect(() => {
    if (queryMutate.isError)
      alert.error({
        message: "¡Ocurrió un error!",
        disclaimer: "Por favor, intenta de nuevo más tarde",
      });
  }, [queryMutate.isError]);

  return queryMutate;
};

export default useCreateMember;
