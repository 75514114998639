import { DocumentFileTypeEnum } from "apps/lead/context/evaluatorContext/EvaluatorTypes";
import { object, mixed } from "yup";

export const requiredText = "Obligatorio";

export const identificationFilesSchema = object().shape({
  [DocumentFileTypeEnum.FRONT_CI]: mixed()
    .nullable()
    .test("fileLength", requiredText, (value) => value.length > 0),
  [DocumentFileTypeEnum.BACK_CI]: mixed()
    .nullable()
    .test("fileLength", requiredText, (value) => value.length > 0),
  [DocumentFileTypeEnum.SELFIE_CI]: mixed()
    .nullable()
    .test("fileLength", requiredText, (value) => value.length > 0),
});
