import { UserApplicant } from "apps/lead/context/evaluatorContext/EvaluatorTypes";
import { MemberPersonalInformation } from "apps/lead/models/Evaluation";

export const parseEmploymentData = (
  userInformation: Partial<UserApplicant>,
  data: MemberPersonalInformation
) => ({
  ...userInformation,
  employment: {
    ...userInformation.employment,
    employer: data.employer,
    employerPhone: data.employerPhone,
    occupation: data.occupation,
  },
});
