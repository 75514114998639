import IconButton from "@houm-com/ui/IconButton";
import { IoArrowForward as ArrowRight } from "@houm-com/ui-icons";

import { classes } from "apps/lead/pages/EvaluatorForm/components/Welcome/WelcomeStyles";
import { useEvaluation } from "apps/lead/context/evaluatorContext/EvaluatorContext";
import { setPage } from "apps/lead/context/evaluatorContext/EvaluatorActions";
import { EvaluatorPages } from "apps/lead/context/evaluatorContext/EvaluatorTypes";

const Welcome = () => {
  const { dispatch } = useEvaluation();

  const onStartEvaluation = () => dispatch(setPage(EvaluatorPages.RESUME));

  return (
    <div className={classes.welcomeRoot}>
      <div className={classes.welcomeInformationRoot}>
        <div className={classes.welcomeInformationContainer}>
          <p className={classes.welcomeTitle}>Comencemos con tu registro</p>
          <div className={classes.welcomeTextContainer}>
            <p>
              Para poder continuar con tu proceso y realizar la evaluación, te
              pediremos que completes la siguiente información:
            </p>
            <ol className={classes.welcomeList}>
              <li>Información básica</li>
              <li>Dirección actual</li>
              <li>Identificación</li>
              <li>Información laboral</li>
            </ol>
          </div>
        </div>
        <div className={classes.welcomeButtonContainer}>
          <IconButton
            size="lg"
            variant="primary"
            position="end"
            icon={<ArrowRight />}
            type="button"
            onClick={onStartEvaluation}
          >
            Comenzar
          </IconButton>
        </div>
      </div>
      <div className={classes.welcomeImageContainer}>
        <img
          src="https://houm-production-public.s3.us-east-1.amazonaws.com/cms-content/welcome_3471dba428.svg"
          alt="welcome"
          aria-label="welcome"
          className={classes.welcomeImage}
        />
      </div>
    </div>
  );
};

export default Welcome;
