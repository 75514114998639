import { useEffect } from "react";
import { FormProvider, SubmitHandler } from "react-hook-form";

import { fullAddressSchema } from "apps/lead/pages/EvaluatorForm/components/AddressForm/utils/validationSchema";
import useUpdateMemberInformation from "apps/lead/pages/EvaluatorForm/hooks/useUpdateMemberInformation";
import { classes } from "apps/lead/pages/EvaluatorForm/components/AddressForm/AddressFormStyles";
import { IAddressForm } from "apps/lead/pages/EvaluatorForm/components/AddressForm/utils/types";
import AddressSuggester from "apps/lead/components/AddressSuggester";
import FormWrapper from "apps/lead/components/EvaluatorForm/FormWrapper/FormWrapper";
import { useEvaluation } from "apps/lead/context/evaluatorContext/EvaluatorContext";
import CallToAction from "apps/lead/components/EvaluatorForm/CallToAction";
import Header from "apps/lead/components/EvaluatorForm/HeaderForm/Header";
import useReactHookForm from "hooks/useReactHookForm";
import {
  setActiveStep,
  setUserInformation,
} from "apps/lead/context/evaluatorContext/EvaluatorActions";
import {
  parseAddressData,
  parseDefaultData,
} from "apps/lead/pages/EvaluatorForm/components/AddressForm/utils/parsers";

const AddressForm = () => {
  const {
    state: { user: userInformation, activeStep, country },
    dispatch,
  } = useEvaluation();
  const { isLoading, mutate, isSuccess, data } = useUpdateMemberInformation({
    memberId: userInformation?.memberId,
    country,
  });
  const { methods, errors } = useReactHookForm<IAddressForm>({
    formSchema: fullAddressSchema,
    formOptions: {
      defaultValues: parseDefaultData(userInformation),
    },
  });

  const onSubmit: SubmitHandler<IAddressForm> = async (data) => {
    if (isLoading) return;
    mutate({
      address: data.fullAddress,
      nationality: userInformation.user.nationality,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(setUserInformation(parseAddressData(userInformation, data)));
      dispatch(setActiveStep(activeStep + 1));
    }
  }, [isSuccess]);

  return (
    <FormProvider {...methods}>
      <Header
        title="Dirección de residencia actual"
        stepTitle="Dirección actual"
      />
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormWrapper classNameFormWrapper={classes.addressFormWrapper}>
          <AddressSuggester
            countryCode={country}
            textFieldProps={{
              id: "fullAddress",
              required: true,
              label: "Dirección",
              errorMessage: errors.fullAddress?.message,
              form: "novalidateform",
              placeholder: "Escribe aquí dónde vives actualmente",
              ...methods.register("fullAddress"),
            }}
            defaultValue={userInformation.extraInformation?.fullAddress}
            onChange={(e) => methods.setValue("fullAddress", e)}
            onCheck={(e) => methods.setValue("isAddressSelected", e)}
          />
        </FormWrapper>
        <CallToAction label="Continuar" isLoading={isLoading} />
      </form>
    </FormProvider>
  );
};

export default AddressForm;
