import { startCase } from "lodash";

import { Evaluation, EvaluationMember } from "apps/lead/models/Evaluation";
import { MemberData } from "apps/lead/pages/EvaluatorForm/utils/types";
import { DocumentFileTypeEnum } from "apps/lead/context/evaluatorContext/EvaluatorTypes";

export const parserMemberRequest = (member: MemberData) => ({
  name: startCase(member.name),
  last_name: startCase(member.lastName),
  second_last_name: startCase(member.secondLastName),
  document: member.document.trim(),
  document_type: member.documentType.toString(),
  email: member.email.toLowerCase().trim(),
  phone: member.phone,
});

export const parseUser = (member: EvaluationMember) => ({
  id: member.userData.id,
  name: member.userData.name,
  lastName: member.userData.lastName,
  secondLastName: member.userData.secondLastName,
  email: member.userData.email,
  phone: member.userData.phone,
  documentType: member.userData.documentType,
  document: member.userData.document,
  nationality: member.personInformation.nationality,
});

export const parserApplicant = (member: EvaluationMember) => ({
  memberId: member?.memberId,
  user: {
    name: member.userData.name,
    lastName: member.userData.lastName,
    secondLastName: member.userData.secondLastName,
    email: member.userData.email,
    phone: member.userData.phone,
    documentType: member.userData.documentType,
    document: member.userData.document,
    nationality: member.personInformation.nationality,
  },
  identityFiles: {
    [DocumentFileTypeEnum.FRONT_CI]: undefined,
    [DocumentFileTypeEnum.BACK_CI]: undefined,
    [DocumentFileTypeEnum.SELFIE_CI]: undefined,
  },
  employmentFiles: {
    [DocumentFileTypeEnum.EMPLOYMENT_CERTIFICATE]: undefined,
    [DocumentFileTypeEnum.TAX_DECLARATION]: undefined,
    [DocumentFileTypeEnum.COMPANY_INCOME_STATEMENT]: undefined,
    [DocumentFileTypeEnum.COMPANY_CONSTITUTION]: undefined,
    [DocumentFileTypeEnum.PENSIONER_CERTIFICATE]: undefined,
  },
  incomeValidationFiles: {
    [DocumentFileTypeEnum.PENSION_SALARY_SETTLEMENT]: undefined,
    [DocumentFileTypeEnum.SALARY_SETTLEMENT]: undefined,
    [DocumentFileTypeEnum.BANK_STATEMENT]: undefined,
  },
  employment: {
    workerType: member.personInformation.incomeSourceType,
    occupation: member.personInformation.occupation,
    employer: member.personInformation.employer,
    employerPhone: member.personInformation.employerPhone,
  },
  extraInformation: {
    fullAddress: member.personInformation.address?.fullAddress,
    role: member.role,
  },
  isCompleted: member.isFormCompleted,
});

export const parseUsersApplicant = (evaluation: Evaluation) =>
  Promise.all(
    evaluation.members.map(async (member) => parserApplicant(member))
  );
