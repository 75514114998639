import { UseQueryResult, useQuery } from "react-query";

import { Evaluation, EvaluationSchema } from "apps/lead/models/Evaluation";
import evaluationService from "apps/lead/services/EvaluationService";
import { Locale } from "models/Countries";

const useEvaluation = ({
  evaluationId,
  country,
}: {
  evaluationId: string;
  country: Locale;
}) => {
  const query: UseQueryResult<Evaluation, Error> = useQuery(
    ["evaluation", evaluationId],
    async () => {
      const { data } = await evaluationService.getEvaluation(
        evaluationId,
        country
      );
      return EvaluationSchema.parse(data);
    },
    {
      enabled: !!country && !!evaluationId,
      retry: false,
    }
  );
  return query;
};

export default useEvaluation;
