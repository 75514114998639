import { useMemo } from "react";

import { reverseTransformFileListArray } from "apps/lead/pages/EvaluatorForm/components/IncomeValidationFiles/utils/utils";
import { useEvaluation } from "apps/lead/context/evaluatorContext/EvaluatorContext";
import { IncomeValidationFiles } from "apps/lead/context/evaluatorContext/EvaluatorTypes";

const useDefaultData = () => {
  const {
    state: {
      user: { incomeValidationFiles },
    },
  } = useEvaluation();

  const defaultFiles = useMemo(() => {
    if (!incomeValidationFiles) return undefined;
    const filteredIncomeValidationFiles = Object.fromEntries(
      Object.entries(incomeValidationFiles).filter(
        ([_, value]) => value !== undefined
      )
    ) as Record<keyof IncomeValidationFiles, FileList[]>;
    return reverseTransformFileListArray<IncomeValidationFiles>(
      filteredIncomeValidationFiles
    );
  }, [incomeValidationFiles]);

  return {
    defaultFiles,
  };
};

export default useDefaultData;
