import DependentForm from "apps/lead/pages/EvaluatorForm/components/IncomeValidationFiles/components/co/DependentForm";
import IndependentForm from "apps/lead/pages/EvaluatorForm/components/IncomeValidationFiles/components/co/IndependentForm";
import RetiredForm from "apps/lead/pages/EvaluatorForm/components/IncomeValidationFiles/components/co/RetiredForm";
import { useEvaluation } from "apps/lead/context/evaluatorContext/EvaluatorContext";
import { DocumentObjectTypeEnum } from "apps/lead/context/evaluatorContext/EvaluatorTypes";
import Header from "apps/lead/components/EvaluatorForm/HeaderForm/Header";
import FormWrapper from "apps/lead/components/EvaluatorForm/FormWrapper";
import useDocumentFiles from "apps/lead/pages/EvaluatorForm/hooks/useDocumentFiles";
import useFinishMemberEvaluation from "apps/lead/pages/EvaluatorForm/components/IncomeValidationFiles/hooks/useFinishMemberEvaluation";
import { IncomeValidationTypes } from "apps/lead/pages/EvaluatorForm/components/IncomeValidationFiles/utils/types";
import useEvaluationHook from "apps/lead/pages/EvaluatorForm/hooks/useEvaluation";
import { IncomeSourceTypeEnum } from "apps/lead/models/Evaluation";

const IncomeValidationFiles = () => {
  const {
    state: {
      user: { employment },
      evaluation: { id: evaluationId },
      country,
    },
  } = useEvaluation();
  const { refetch, isRefetching } = useEvaluationHook({
    evaluationId,
    country,
  });
  const { onResetForm } = useFinishMemberEvaluation();

  const { uploadDocuments, isLoading } = useDocumentFiles<
    IncomeValidationTypes<typeof employment.workerType>
  >({
    documentType: DocumentObjectTypeEnum.INCOME_VALIDATION,
    onSuccess: () => refetch().then((e) => onResetForm(e.data)),
  });

  function onFinishSubmit<
    T extends keyof IncomeValidationTypes<typeof employment.workerType>
  >(data: Record<T, FileList[]>) {
    // @ts-ignore
    uploadDocuments(data);
  }
  return (
    <>
      <Header
        title="Validación de ingresos"
        subtitle="Estos documentos pueden estar en formato PDF o como imagen (JPEG, JPG o PNG)"
        stepTitle="Validación laboral e ingresos"
      />
      <FormWrapper classNameFormWrapper="flex flex-col">
        {employment?.workerType === IncomeSourceTypeEnum.INDEPENDENT && (
          <IndependentForm
            onFinishSubmit={(data) => onFinishSubmit(data)}
            isLoading={isLoading || isRefetching}
          />
        )}
        {employment?.workerType === IncomeSourceTypeEnum.DEPENDENT && (
          <DependentForm
            onFinishSubmit={(data) => onFinishSubmit(data)}
            isLoading={isLoading || isRefetching}
          />
        )}
        {employment?.workerType === IncomeSourceTypeEnum.RETIRED && (
          <RetiredForm
            onFinishSubmit={(data) => onFinishSubmit(data)}
            isLoading={isLoading || isRefetching}
          />
        )}
      </FormWrapper>
    </>
  );
};

export default IncomeValidationFiles;
